<template>
  <base-modal-ex :active.sync="isActive"
    :animation-in="animationIn"
    :animation-out="animationOut"
    :animation-duration="animationDuration"
    :can-cancel="false"
    :min-width="40"
    has-modal-card>
    <header class="modal-card-head">
      <p class="modal-card-title"
        :class="{ 'is-flex-items-center' : showIcon}">
        <span v-if="showIcon"
          class="icon is-medium"
          :class="`has-text-${iconType}`"
          style="margin-right: 0.5rem">
          <i class="mdi mdi-36px"
            :class="`${iconName}`" />
        </span>
        <span>{{ jobInOut === 0 ? 'Due In Notes' : 'Due Out Notes' }}</span>
      </p>
    </header>
    <section class="modal-card-body">
      <div class="slot-height">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <div class="columns is-gapless">
              <div class="column">
                <span class="label">Job No</span>
                <span class="is-size-6">{{ jobNo }}</span>
              </div>
              <div class="column"
                style="height: 100%">
                <div class="field pb-5">
                  <div class="control is-pulled-right">
                    <a class="button is-small is-success tooltip is-tooltip"
                      :data-tooltip="!isAddNote ? 'Add new note' : 'Close add new note'"
                      @click="toggleNote(isAddNote)">
                      <span class="icon">
                        <i class="mdi mdi-18px"
                          :class="!isAddNote ? 'mdi-plus' : 'mdi-close'" />
                      </span>
                      <span>{{ isAddNote ? 'Close Note' : 'Add Note' }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <expand-transition>
              <div v-show="isAddNote">
                <div class="field pb-0">
                  <div class="control">
                    <textarea
                      class="textarea"
                      v-model="innerValue.remarks"
                      placeholder="Add note here"
                      :class="{'is-danger' : $v.newNote.remarks.$error}"
                      @input="$v.newNote.remarks.$touch();"
                      v-focus-inserted />
                    <span class="help is-danger"
                      v-if="$v.newNote && !$v.newNote.remarks.required">Note is required.</span>
                  </div>
                </div>
                <div class="field pb-3">
                  <div class="control is-flex is-justify-content-right">
                    <button class="button is-small is-primary tooltip is-tooltip"
                      @click="save()"
                      data-tooltip="Save new note">
                      <span class="icon">
                        <i class="mdi mdi-pencil mdi-18px" />
                      </span>
                      <span>Save Note</span>
                    </button>
                  </div>
                </div>
              </div>
            </expand-transition>
            <div class="is-flex is-align-items-center pb-0 pt-3 has-text-primary">
              <span class="icon is-small">
                <i class="mdi mdi-note-text-outline mdi-20px"
                  aria-hidden="true" />
              </span>
              <span class="pl-1">Notes</span>
            </div>
            <div class="is-divider mt-2 mb-3"
              style="width: 100%" />
            <div v-if="entitySummaries && entitySummaries.length === 0">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </div>
            <div class="container has-background-light">
              <div v-for="(note, index) in entitySummaries"
                :key="index"
                class="is-flex px-3 py-2">
                <transition mode="out-in"
                  name="custom-classes-transition"
                  :enter-active-class="`animate__animated animate__slideInRight animate_fastest`"
                  :leave-active-class="`animate__animated animate__slideInRight animate_fastest`">
                  <article class="message is-flex is-info">
                    <div class="message-body py-1 px-2 message-list"
                      :key="note.remarkId">
                      <span class="">{{ note.remarks }}</span>
                      <div class="is-flex is-align-items-center is-size-7">
                        <user-profile-icon v-if="note.firstName || note.displayName"
                          :is-text-profile="true"
                          :first-name="note.firstName"
                          :last-name="note.lastName"
                          :display-name="note.displayName"
                          :width="20"
                          :height="20"
                          class="pr-1" />
                        <span class="is-italic">{{ $filters.formatDateTimeLocale(note.remarkDateTime, $userInfo.locale) }}</span>
                      </div>
                    </div>
                  </article>
                </transition>
              </div>
            </div>
          </article>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <div :style="`width: 100%; display: flex; justify-content: space-between;`">
        <div align="right"
          style="width: 100%">
          <button class="button is-primary tooltip"
            @click="close()"
            data-tooltip="Close">
            <span class="icon">
              <i class="mdi mdi-close mdi-18px" />
            </span>
            <span>Close</span>
          </button>
        </div>
      </div>
    </footer>
  </base-modal-ex>
</template>

<script>
import { BaseModalEx } from '@/components/BulmaModal'
import { FocusInserted } from '@/components/directives'
import _cloneDeep from 'lodash/cloneDeep'
import { RemarkTypes } from '@/enums'
import { RemarkModel } from '@/classes/viewmodels'
import RemarkService from '@/services/RemarkService'
import ProductionInOutValidation from './ProductionInOutValidation'
import UserProfileIcon from '@/components/UserProfileIcon/UserProfileIcon'
import { ExpandTransition } from '@/components/Transitions'
import _orderBy from 'lodash/orderBy'
import integer from 'vuelidate/lib/validators/integer'

export default {
  name: 'ProductionNoteModal',
  inject: ['$vv'],
  components: {
    BaseModalEx,
    UserProfileIcon,
    ExpandTransition
  },
  directives: {
    FocusInserted
  },
  mixins: [ProductionInOutValidation],
  props: {
    quoteId: null,
    jobNo: null,
    value: null,
    jobInOut: {
      type: integer,
      default: 0
    },
    active: {
      type: Boolean,
      default: false
    },
    animationIn: {
      type: String,
      default: 'zoomIn'
    },
    animationOut: {
      type: String,
      default: 'zoomOut'
    },
    animationDuration: {
      type: [String, Number],
      default: 0.3
    },
    buttonLocation: {
      type: String,
      default: 'end' // valid values 'start' & 'end'
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    iconType: {
      type: String,
      default: 'primary'
    },
    iconName: {
      type: String,
      default: 'mdi-note-edit'
    }
  },
  data() {
    return {
      isActive: false,
      innerValue: _cloneDeep(this.value),
      entitySummaries: null,
      isAddNote: false,
      isNoteEmpty: true
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    remarkType() {
      return this.jobInOut === 0 ? RemarkTypes.DueInNote : RemarkTypes.DueOutNote
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.getNoteEntities()
  },
  mounted() {
    this.isActive = this.active || false
    this.$v.newNote.$touch()
  },
  methods: {
    async getNoteEntities() {
      this.entitySummaries = await RemarkService.getRemarkEntities(this.quoteId, this.remarkType)
      this.entitySummaries = _orderBy(this.entitySummaries, 'createdDate', 'desc')
    },
    toggleNote(isAdd) {
      if (!isAdd) {
        this.isAddNote = true
      } else {
        this.isAddNote = false
      }
    },
    async save() {
      if (this.innerValue.remarks !== null && this.innerValue.remarks !== '') {
        try {
          this.$showSpinner('Saving...')
          await RemarkService.postEntity(this.innerValue)

          this.toggleNote(false)
          await this.getNoteEntities()
          this.innerValue = new RemarkModel(this.quoteId, this.remarkType)
          this.isAddNote = false
          this.$hideSpinner()
          return true
        } catch (e) {
          this.$hideSpinner()

          return false
        } finally {

          this.$hideSpinner()
        }
      }
    },
    close() {
      this.$emit('update:active', false)
      this.$emit('close', this.entitySummaries)
    }
  }
}
</script>

<style lang="scss" scoped>
.is-flex-items-center {
  display: flex;
  align-items: center;
}
.slot-height {
  max-height: 100%;
  overflow: hidden;
}
article.message {
  width: 90%;
}
.message-body {
  width: 100%;
}
.message-list {
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}
.container {
  max-height: calc(60vh - 18em);
  overflow-y: auto;
  scroll-behavior: smooth;
}
@keyframes highlight-unread {
  0% {
    filter: brightness(0.4);
    filter: contrast(0.4);
    -webkit-filter: brightness(0.4);
    -webkit-filter: contrast(0.4);
  }
  50% {
    filter: brightness(0.6);
    filter: contrast(0.6);
    -webkit-filter: brightness(0.6);
    -webkit-filter: contrast(0.6);
  }
  100% {
    filter: brightness(1);
    filter: contrast(1);
    -webkit-filter: brightness(1);
    -webkit-filter: contrast(1);
  }
}
.unread-animation {
  animation-name: highlight-unread;
  animation-duration: 100s;
  animation-iteration-count: 1;
  font-weight: bold;
}
</style>
