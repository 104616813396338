export default {
  None: 0,
  Remark: 1,
  PrintedRemark: 2,
  RR: 3,
  REP: 4,
  PAINT: 5,
  MECH: 6,
  CD: 7,
  FIBER: 8,
  CRUSH: 9,
  OPG: 10,
  MISC: 11,
  SUBL: 12,
  INCNOT: 13,
  QUICK: 14,
  PART: 15,
  RWA: 16,
  PURCHASEORDER: 17,
  RETURNPARTS: 18,
  ORMSystemRemark: 19,
  PNETSystemRemark: 20,
  OtherSystemRemark: 21,
  ORMMessageRemark: 22,
  PNETMessageRemark: 23,
  PDRRR: 24,
  PDRRE: 25,
  Contact: 26,
  DueInNote: 27,
  DueOutNote: 28
}
