<template>
  <div class="">
    <div>
      <a name="btnNotifications"
        class="button is-light"
        :data-badge="$notifications.length > 0 ? $notifications.length : null"
        :class="[{ 'has-badge-danger has-badge-rounded has-badge-small': $notifications.length > 0 }]">
        <span class="icon has-text-primary">
          <!-- <i class="mdi mdi-bell mdi-24px" /> -->
          <icon icon="mdi:bell"
            class="mdi mdi-24px" />
        </span>
      </a>
    </div>
    <tippy ref="tippyNotifications"
      to="btnNotifications"
      arrow
      :interactive="true"
      theme="light"
      :max-width="500">
      <div class="container p-2 is-flex is-justify-content-space-between is-align-content-center">
        <span class="is-size-5">Notifications</span>
      </div>
      <div class="is-divider mb-1 mt-0" />
      <div class="container">
        <div v-if="$notifications.length === 0"
          class="notification is-light px-5 mb-1">
          <span>No new notifications</span>
        </div>
        <template v-for="(notification) in $notifications">
          <a v-if="notification.route"
            @click="gotoRoute(notification.route)"
            :key="notification.id">
            <div class="notification is-light px-5 mb-1"
              :class="[{'is-primary': notification.severity === 1}, {'is-warning': notification.severity === 2}, {'is-danger': notification.severity === 3}]"
              :key="notification.id">
              <button v-if="notification.type !== 3 && notification.type !== 4"
                class="delete"
                @click="setAsRead(notification.id)" />
              <span v-html="notification.content" />
            </div>
          </a>
          <div v-else
            class="notification is-light px-5 mb-1"
            :class="[{'is-primary': notification.severity === 1}, {'is-warning': notification.severity === 2}, {'is-danger': notification.severity === 3}]"
            :key="notification.id">
            <button v-if="notification.type !== 3 && notification.type !== 4"
              class="delete"
              @click="setAsRead(notification.id)" />
            <span v-html="notification.content" />
          </div>
        </template>
      </div>
    </tippy>
  </div>
</template>

<script>
import { NotificationService } from '@/services'
import { Icon } from '@iconify/vue2'

export default {
  name: 'Notification',
  components: {
    Icon
  },
  mixins: [],
  props: {},
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async setAsRead(id) {
      // this.stopNotificationPoll()
      await NotificationService.setNotificationStatus(id, 2)
      this.getNotifications()
      // this.startNotificationPoll()
    },
    gotoRoute(route) {
      this.$router.push(route)
    },
    gotoRouteNewTab(route) {
      window.open(route, '_blank')
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.notification {
  width: 30em;
}
.big-icon {
  font-size: 72px;
}
</style>