/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.QuoteTemplate = (function() {

    /**
     * Namespace QuoteTemplate.
     * @exports QuoteTemplate
     * @namespace
     */
    var QuoteTemplate = {};

    QuoteTemplate.QuoteTemplateListComboModel = (function() {

        /**
         * Properties of a QuoteTemplateListComboModel.
         * @memberof QuoteTemplate
         * @interface IQuoteTemplateListComboModel
         * @property {Array.<QuoteTemplate.QuoteTemplateListComboModel.IQuoteTemplate>|null} [quoteTemplates] QuoteTemplateListComboModel quoteTemplates
         */

        /**
         * Constructs a new QuoteTemplateListComboModel.
         * @memberof QuoteTemplate
         * @classdesc Represents a QuoteTemplateListComboModel.
         * @implements IQuoteTemplateListComboModel
         * @constructor
         * @param {QuoteTemplate.IQuoteTemplateListComboModel=} [p] Properties to set
         */
        function QuoteTemplateListComboModel(p) {
            this.quoteTemplates = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * QuoteTemplateListComboModel quoteTemplates.
         * @member {Array.<QuoteTemplate.QuoteTemplateListComboModel.IQuoteTemplate>} quoteTemplates
         * @memberof QuoteTemplate.QuoteTemplateListComboModel
         * @instance
         */
        QuoteTemplateListComboModel.prototype.quoteTemplates = $util.emptyArray;

        /**
         * Encodes the specified QuoteTemplateListComboModel message. Does not implicitly {@link QuoteTemplate.QuoteTemplateListComboModel.verify|verify} messages.
         * @function encode
         * @memberof QuoteTemplate.QuoteTemplateListComboModel
         * @static
         * @param {QuoteTemplate.IQuoteTemplateListComboModel} m QuoteTemplateListComboModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteTemplateListComboModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.quoteTemplates != null && m.quoteTemplates.length) {
                for (var i = 0; i < m.quoteTemplates.length; ++i)
                    $root.QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate.encode(m.quoteTemplates[i], w.uint32(10).fork()).ldelim();
            }
            return w;
        };

        /**
         * Encodes the specified QuoteTemplateListComboModel message, length delimited. Does not implicitly {@link QuoteTemplate.QuoteTemplateListComboModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof QuoteTemplate.QuoteTemplateListComboModel
         * @static
         * @param {QuoteTemplate.IQuoteTemplateListComboModel} message QuoteTemplateListComboModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        QuoteTemplateListComboModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes a QuoteTemplateListComboModel message from the specified reader or buffer.
         * @function decode
         * @memberof QuoteTemplate.QuoteTemplateListComboModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {QuoteTemplate.QuoteTemplateListComboModel} QuoteTemplateListComboModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteTemplateListComboModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.QuoteTemplate.QuoteTemplateListComboModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    if (!(m.quoteTemplates && m.quoteTemplates.length))
                        m.quoteTemplates = [];
                    m.quoteTemplates.push($root.QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate.decode(r, r.uint32()));
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes a QuoteTemplateListComboModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof QuoteTemplate.QuoteTemplateListComboModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {QuoteTemplate.QuoteTemplateListComboModel} QuoteTemplateListComboModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        QuoteTemplateListComboModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        QuoteTemplateListComboModel.QuoteTemplate = (function() {

            /**
             * Properties of a QuoteTemplate.
             * @memberof QuoteTemplate.QuoteTemplateListComboModel
             * @interface IQuoteTemplate
             * @property {string|null} [id] QuoteTemplate id
             * @property {string|null} [name] QuoteTemplate name
             */

            /**
             * Constructs a new QuoteTemplate.
             * @memberof QuoteTemplate.QuoteTemplateListComboModel
             * @classdesc Represents a QuoteTemplate.
             * @implements IQuoteTemplate
             * @constructor
             * @param {QuoteTemplate.QuoteTemplateListComboModel.IQuoteTemplate=} [p] Properties to set
             */
            function QuoteTemplate(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            /**
             * QuoteTemplate id.
             * @member {string|null|undefined} id
             * @memberof QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate
             * @instance
             */
            QuoteTemplate.prototype.id = null;

            /**
             * QuoteTemplate name.
             * @member {string|null|undefined} name
             * @memberof QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate
             * @instance
             */
            QuoteTemplate.prototype.name = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * QuoteTemplate _id.
             * @member {"id"|undefined} _id
             * @memberof QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate
             * @instance
             */
            Object.defineProperty(QuoteTemplate.prototype, "_id", {
                get: $util.oneOfGetter($oneOfFields = ["id"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * QuoteTemplate _name.
             * @member {"name"|undefined} _name
             * @memberof QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate
             * @instance
             */
            Object.defineProperty(QuoteTemplate.prototype, "_name", {
                get: $util.oneOfGetter($oneOfFields = ["name"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Encodes the specified QuoteTemplate message. Does not implicitly {@link QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate.verify|verify} messages.
             * @function encode
             * @memberof QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate
             * @static
             * @param {QuoteTemplate.QuoteTemplateListComboModel.IQuoteTemplate} m QuoteTemplate message or plain object to encode
             * @param {$protobuf.Writer} [w] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuoteTemplate.encode = function encode(m, w) {
                if (!w)
                    w = $Writer.create();
                if (m.id != null && Object.hasOwnProperty.call(m, "id"))
                    w.uint32(10).string(m.id);
                if (m.name != null && Object.hasOwnProperty.call(m, "name"))
                    w.uint32(18).string(m.name);
                return w;
            };

            /**
             * Encodes the specified QuoteTemplate message, length delimited. Does not implicitly {@link QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate.verify|verify} messages.
             * @function encodeDelimited
             * @memberof QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate
             * @static
             * @param {QuoteTemplate.QuoteTemplateListComboModel.IQuoteTemplate} message QuoteTemplate message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QuoteTemplate.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QuoteTemplate message from the specified reader or buffer.
             * @function decode
             * @memberof QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate
             * @static
             * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
             * @param {number} [l] Message length if known beforehand
             * @returns {QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate} QuoteTemplate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuoteTemplate.decode = function decode(r, l) {
                if (!(r instanceof $Reader))
                    r = $Reader.create(r);
                var c = l === undefined ? r.len : r.pos + l, m = new $root.QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate();
                while (r.pos < c) {
                    var t = r.uint32();
                    switch (t >>> 3) {
                    case 1:
                        m.id = r.string();
                        break;
                    case 2:
                        m.name = r.string();
                        break;
                    default:
                        r.skipType(t & 7);
                        break;
                    }
                }
                return m;
            };

            /**
             * Decodes a QuoteTemplate message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {QuoteTemplate.QuoteTemplateListComboModel.QuoteTemplate} QuoteTemplate
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QuoteTemplate.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            return QuoteTemplate;
        })();

        return QuoteTemplateListComboModel;
    })();

    return QuoteTemplate;
})();

module.exports = $root;
