export default {
  AddNew: 'addnew',
  Print: 'print',
  Delete: 'delete',
  Save: 'save',
  Cancel: 'cancel',
  NewSupp: 'newsupp',
  CopyQuote: 'copyquote',
  ExportQuote: 'exportquote',
  PrintDebtorListStatement: 'printdebtorliststatement',
  AdvancePaymentEntry: 'advancepaymententry',
  FactorInvoices: 'FactorInvoices',
  AdvanceReceiptEntry: 'advancereceiptentry',
  BankUndepositedFunds: 'bankundepositedfunds',
  ToggleSide: 'toggleside'
}
