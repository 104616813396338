import { CompanyService, CompanySettingService } from '@/services'
import { CompanyModel } from '@/classes/viewmodels'
import { getCurrency, getCurrencySymbol } from '@/components/currency'

const state = {
  company: new CompanyModel()
}

const getters = {
  company: state => state.company
}

const actions = {
  async getCompanyItemCategories({ commit }) {
    const result = await CompanyService.getItemTypes()
    commit('setItemCategories', result)
  },
  async getCompanySetting({ commit }) {
    const result = await CompanySettingService.getEntity()
    commit('setSetting', result)
  },
  async getCompanyInfo({ commit }) {
    const result = await CompanyService.getEntity()
    commit('setCompanyInfo', result)
  }
}

const mutations = {
  setItemCategories(state, data) {
    state.company.itemCategories = data
  },
  setSetting(state, data) {
    state.company.setting = data
  },
  setCompanyInfo(state, data) {
    state.company.info = data
    state.company.currency.currency = getCurrency(data.locale)
    state.company.currency.symbol = getCurrencySymbol(data.locale)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
