<template>
  <nav class="navbar"
    :class="[`is-app-detail-header-${$user.info.theme}`, `navbar-${$user.info.theme}`]">
    <div class="navbar-brand">
      <div class="navbar-item is-hidden-desktop">
        <div class="field is-grouped">
          <p class="control">
            <a id="toggle"
              class="button tooltip is-tooltip is-tooltip-topleft is-primary"
              :class="buttonCSS"
              :disabled="!buttonsEnabled"
              @click="toggleSide"
              title="Toggle Side Menu"
              data-tooltip="Toggle Side Menu">
              <span class="icon">
                <i class="mdi mdi-menu" />
                <section class="hero is-primary hero-header has-sticky-module-header">
                  <div class="hero-body is-paddingless">
                    <nav class="navbar">
                      <div class="navbar-brand">
                        <div class="navbar-item is-hidden-desktop">
                          <div class="field is-grouped">
                            <p class="control">
                              <a id="toggle"
                                class="button tooltip is-tooltip is-tooltip-topleft is-primary"
                                :class="buttonCSS"
                                @click="toggleSide"
                                title="Toggle Side Menu"
                                data-tooltip="Toggle Side Menu">
                                <span class="icon">
                                  <i class="mdi mdi-menu" />
                                </span>
                              </a>
                            </p>
                            <p v-if="!licenceExpired && access.isAdd && !serviceError && showNew"
                              class="control">
                              <a id="addnew"
                                class="button tooltip is-tooltip-topleft"
                                :class="buttonCSS"
                                :disabled="!buttonsEnabled"
                                @click="addNew"
                                title="Add New"
                                data-tooltip="Add New">
                                <span class="icon">
                                  <i class="mdi mdi-plus" />
                                </span>
                              </a>
                            </p>
                            <p v-if="access.isPrint && !serviceError && showPrint"
                              class="control">
                              <a id="print"
                                class="button tooltip is-tooltip-topleft"
                                :class="buttonCSS"
                                :disabled="!buttonsEnabled"
                                @click="print"
                                title="Print"
                                data-tooltip="Print">
                                <span class="icon">
                                  <i class="mdi mdi-printer" />
                                </span>
                              </a>
                            </p>
                            <p v-if="!licenceExpired && access.isDelete && !serviceError && showDelete"
                              class="control">
                              <a id="delete"
                                class="button tooltip is-tooltip-topleft"
                                :class="buttonCSS"
                                :disabled="!buttonsEnabled"
                                @click="trash"
                                title="Delete"
                                data-tooltip="Delete">
                                <span class="icon">
                                  <i class="mdi mdi-delete" />
                                </span>
                              </a>
                            </p>
                            <p v-if="!licenceExpired && access.isEdit && !serviceError && showSave"
                              class="control">
                              <a id="save"
                                class="button tooltip is-tooltip-topleft"
                                :class="buttonCSS"
                                @click="save"
                                title="Save"
                                data-tooltip="Save">
                                <span class="icon">
                                  <i class="mdi mdi-content-save" />
                                </span>
                              </a>
                            </p>
                            <p v-if="showCancel"
                              class="control">
                              <a id="cancel"
                                class="button tooltip is-tooltip-topleft"
                                :class="buttonCSS"
                                @click="cancel"
                                title="Go Back"
                                data-tooltip="Go Back">
                                <span class="icon">
                                  <i class="mdi mdi-arrow-left-bold" />
                                </span>
                              </a>
                            </p>
                            <p v-if="!serviceError && showLock"
                              class="control">
                              <a id="lock"
                                class="button tooltip is-tooltip-topleft"
                                :class="buttonCSS"
                                :data-tooltip="[entity.readOnly ? 'Unlock' : 'Lock']"
                                :title="[entity.readOnly ? 'Unlock' : 'Lock']"
                                @click="toggleReadOnly()">
                                <span class="icon">
                                  <i class="mdi"
                                    :class="[entity.readOnly ? 'mdi-lock-outline' : 'mdi-lock-open-outline']" />
                                </span>
                              </a>
                            </p>
                          </div>
                        </div>
                        <div class="navbar-burger burger"
                          data-target="action-button-menu">
                          <span />
                          <span />
                          <span />
                        </div>
                      </div>
                      <div id="detail-nav-menu"
                        class="navbar-start is-hidden-touch">
                        <div class="navbar-item">
                          <h1 class="title">
                            <a class="button is-primary is-outlined"
                              v-if="showToggle"
                              :class="buttonCSS"
                              :disabled="!buttonsEnabled"
                              @click="toggleSide">
                              <span class="icon">
                                <i class="mdi mdi-menu mdi-24px" />
                              </span>
                            </a>
                            <span v-for="(route, idx) in $route.matched.slice()"
                              :key="idx">
                              <span v-if="idx > 0 && route.meta.title"
                                style="transform: translateY(0.2rem);"
                                class="icon">
                                <i class="mdi mdi-chevron-right mdi-36px" />
                              </span>
                              <span>
                                {{ title || route.meta.title }}
                              </span>
                            </span>
                          </h1>
                        </div>
                      </div>
                    </nav>
                  </div>
                </section>
              </span></a>
          </p>
          <p v-if="!licenceExpired && access.isAdd && !serviceError && showNew"
            class="control">
            <a id="addnew"
              class="button tooltip is-tooltip-topleft"
              :class="buttonCSS"
              :disabled="!buttonsEnabled"
              @click="addNew"
              title="Add New"
              data-tooltip="Add New">
              <span class="icon">
                <i class="mdi mdi-plus" />
              </span>
            </a>
          </p>
          <p v-if="access.isPrint && !serviceError && showPrint"
            class="control">
            <a id="preview"
              class="button tooltip is-tooltip-topleft"
              :class="buttonCSS"
              :disabled="!buttonsEnabled"
              @click="print"
              title="Preview"
              data-tooltip="Preview">
              <span class="icon">
                <i class="mdi mdi-printer" />
              </span>
            </a>
          </p>
          <p v-if="!licenceExpired && access.isDelete && !serviceError && showDelete"
            class="control">
            <a id="delete"
              class="button tooltip is-tooltip-topleft"
              :class="buttonCSS"
              :disabled="!buttonsEnabled"
              @click="trash"
              title="Delete"
              data-tooltip="Delete">
              <span class="icon">
                <i class="mdi mdi-delete" />
              </span>
            </a>
          </p>
          <p v-if="!licenceExpired && access.isEdit && !serviceError && showSave"
            class="control">
            <a id="save"
              class="button tooltip is-tooltip-topleft"
              :class="buttonCSS"
              :disabled="!buttonsEnabled"
              @click="save"
              title="Save"
              data-tooltip="Save">
              <span class="icon">
                <i class="mdi mdi-content-save" />
              </span>
            </a>
          </p>
          <p v-if="showCancel"
            class="control">
            <a id="cancel"
              class="button tooltip is-tooltip-topleft"
              :class="buttonCSS"
              :disabled="!buttonsEnabled"
              @click="cancel"
              title="Go Back"
              data-tooltip="Go Back">
              <span class="icon">
                <i class="mdi mdi-arrow-left-bold" />
              </span>
            </a>
          </p>
        </div>
      </div>
      <div class="navbar-burger burger"
        data-target="action-button-menu">
        <span />
        <span />
        <span />
      </div>
    </div>
    <div id="detail-nav-menu"
      class="navbar-start is-hidden-touch">
      <!-- <h1 class="title"> -->
      <a class="navbar-item is-primary"
        v-if="showToggle && false"
        :disabled="!buttonsEnabled"
        @click="toggleSide">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <div class="icon">
            <i class="mdi mdi-menu mdi-24px" />
          </div>
          <div class="is-size-8">{{ showSideMenu ? 'Hide' : 'Show' }}</div>
        </div>
      </a>
      <a v-if="showCancel"
        id="cancel"
        class="navbar-item"
        :disabled="!buttonsEnabled"
        @click="cancel">

        <span class="px-4 py-1 has-background-grey-800 is-flex is-flex-direction-column is-justify-content-center is-align-items-center"
          style="border-radius: 5px;">
          <span class="icon">
            <i class="mdi mdi-arrow-left mdi-24px" />
          </span>
          <span class="is-size-8">BACK
          </span>
        </span>

      </a>
      <template v-if="$route.name !== 'QuoteListView' && $route.path.includes('/quotes/') && entity && entity.subQuoteNos">
        <div class="navbar-item has-dropdown is-hoverable"
          name="quoteNumberDropdown">
          <a class="navbar-link is-arrowless">
            <div class="is-flex">
              <div>Quote No: {{ entity.quoteNo }}{{ entity.subQuoteNo > 0 ? `/${entity.subQuoteNo}` : '' }}</div>
            </div>

            <span v-show="entity.subQuoteNos?.length > 1"
              class="has-badge-rounded has-badge-small"
              style="margin-top: -1.3em; margin-left: 8px;"
              :data-badge="entity.subQuoteNos?.length - 1" />

            <div class="mx-2">
              <span class="mdi mdi-20px"
                :class="entity.readOnly ? ' mdi-lock-outline' : 'mdi-lock-open-variant-outline'"
                :title="entity.readOnly ? 'Quote is locked' : 'Quote is unlocked'" />
            </div>

            <div>
              <span class="mdi mdi-12px tag is-primary"
                :class="entity.quotingMethod === 'H' ? 'H' : 'D'"
                :title="entity.quotingMethod === 'H' ? 'Quote is using Hours' : 'Quote is using Dollars'">
                {{ entity.quotingMethod === 'H' ? 'H' : 'D' }}
              </span>
            </div>
          </a>
        </div>
        <tippy ref="tippyQuoteNumberDropdown"
          v-if="entity.subQuoteNos && entity.subQuoteNos.length > 1"
          to="quoteNumberDropdown"
          trigger="click"
          :interactive="true"
          theme="dark"
          :max-width="500">
          <router-link v-for="(sub, index) in entity.subQuoteNos"
            :key="index"
            :to="{ name: $route.name, params: { quoteId: sub.value }, query: $route.query }"
            active-class="is-active">
            <div class="menu more-menu">
              <ul class="menu-list">
                <li>
                  <a class="has-text-light">{{ entity.quoteNo }}{{ sub.key > 0 ? `/${sub.key}` : '' }}</a>
                </li>
              </ul>
            </div>
          </router-link>
        </tippy>
        <div class="is-divider-vertical px-1" />
      </template>
      <quote-detail-header-start-menu v-if="$route.name !== 'QuoteListView' && $route.path.includes('/quotes/')"
        :side-sub-menu="sideSubMenu"
        :entity="entity"
        :vehicle="vehicle" />
      <div v-else-if="$route.name !== 'QuoteListView'"
        class="subtitle is-flex is-align-items-center pl-2">
        <span v-for="(route, idx) in $route.matched.slice()"
          :key="idx">
          <span v-if="idx > 0 && route.meta.title"
            style="transform: translateY(0.2rem);"
            class="icon">
            <i class="mdi mdi-chevron-right mdi-24px" />
          </span>
          <span>{{ title || route.meta.title }}</span>
        </span>
      </div>
    </div>
    <div class="navbar-end is-hidden-touch">
      <a v-if="!licenceExpired && access.isAdd && !serviceError && showNew"
        id="addnew"
        class="navbar-item px-1"
        @click="addNew">
        <div :class="buttonClasses('is-primary')">
          <div class="icon">
            <i class="mdi mdi-plus mdi-16px" />
          </div>
          <div class="is-size-8">{{ addNewText }}</div>
        </div>
      </a>
      <a v-if="!licenceExpired && access.isEdit && !serviceError && showSave"
        id="save"
        class="navbar-item px-1"
        @click="save">
        <div :class="buttonClasses('is-success')">
          <div class="icon">
            <i class="mdi mdi-content-save mdi-16px" />
          </div>
          <div class="is-size-8">Save</div>
        </div>
      </a>
      <div v-if="showOptionBtn"
        name="headerMoreButtons"
        class="navbar-item is-hoverable p-0"
        :title="areTemplatesDisabled ? 'Save changes to the Quote to view options' : ''"
        :class="{'disabled': !showOptions}">
        <a class="navbar-link px-1 is-arrowless">
          <div :class="buttonClasses('is-outlined is-grey-700')">
            <div class="is-size-8">Options</div>
            <div class="icon">
              <i class="mdi mdi-chevron-down mdi-24px" />
            </div>
          </div>
        </a>
      </div>
      <tippy ref="tippyHeaderButtons"
        v-if="showOptionBtn"
        to="headerMoreButtons"
        trigger="click"
        arrow
        :interactive="true"
        theme="dark"
        :max-width="500">
        <div class="menu more-menu">
          <ul v-if="isQuoteRoutes"
            class="menu-list">
            <li>
              <a class="is-flex is-align-items-center has-text-light"
                :class="{'disabled' : snapshotDiff}"
                @click="createNewSupp">
                <span class="icon pr-3">
                  <i class="mdi mdi-plus mdi-20px" />
                </span>
                <span>New Additional</span>
              </a>
            </li>
            <li>
              <a class="is-flex is-align-items-center has-text-light"
                :class="{'disabled' : snapshotDiff}"
                @click="copyQuote">
                <span class="icon pr-3">
                  <i class="mdi mdi-content-copy mdi-20px" />
                </span>
                <span>Copy Quote</span>
              </a>
            </li>
            <li>
              <a class="is-flex is-align-items-center has-text-light"
                :class="{'disabled' : snapshotDiff}"
                @click="exportQuote">
                <span class="icon pr-3">
                  <i class="mdi mdi-export mdi-20px" />
                </span>
                <span>Export Quote</span>
              </a>
            </li>
            <li v-if="access.isPrint && !serviceError && showPrint">
              <a id="print"
                class="is-flex is-align-items-center has-text-light"
                @click="print">
                <span class="icon pr-3">
                  <i class="mdi mdi-printer mdi-20px" />
                </span>
                <span>Preview</span>
              </a>
            </li>
            <li v-if="!serviceError && showLock">
              <a class="ïs-flex is-align-items-center has-text-light"
                @click="toggleReadOnly()">
                <span class="icon pr-3">
                  <i class="mdi mdi-20px"
                    :class="[entity.readOnly ? 'mdi-lock-outline' : 'mdi-lock-open-outline']" />
                </span>
                <span>{{ entity.readOnly ? 'Unlock' : 'Lock' }}</span>
              </a>
            </li>
            <li :title="areTemplatesDisabled ? 'Templates are currently not available for NTAR or LTAR quotes' : ''">
              <a class="is-flex is-align-items-center has-text-light"
                :class="{'disabled': areTemplatesDisabled || (!!entity && entity?.quotingMethod === quotingMethodTypes?.Dollar) }"
                @click="createNewTemplate">
                <span class="icon pr-3">
                  <i class="mdi mdi-file-document-edit-outline mdi-20px" />
                </span>
                <span>Save as Template</span>
              </a>
              <span v-if="areTemplatesDisabled"
                class="has-text-danger is-size-7 ml-5">NTAR/LTAR not supported</span>
            </li>
            <div v-if="!licenceExpired && access.isDelete && !serviceError && showDelete"
              class="is-divider" />
            <li>
              <a v-if="!licenceExpired && access.isDelete && !serviceError && showDelete"
                id="delete"
                class="has-text-danger is-flex is-align-items-center has-text-light"
                @click="trash">
                <span class="icon pr-3">
                  <i class="mdi mdi-trash-can-outline mdi-20px" />
                </span>
                <span>Delete</span>
              </a>
            </li>
          </ul>
          <ul v-if="isDebtorListRoutes"
            class="menu-list">
            <li>
              <a class="is-flex is-align-items-center has-text-light"
                @click="printStatement">
                <span class="icon pr-3">
                  <i class="mdi mdi-printer mdi-20px" />
                </span>
                <span>Print Statement</span>
              </a>
            </li>
          </ul>
          <ul v-if="isPaymentEntryRoutes"
            class="menu-list">
            <li>
              <a class="is-flex is-align-items-center has-text-light"
                @click="advancePaymentEntry">
                <span class="icon pr-3">
                  <i class="mdi mdi-account-credit-card-outline mdi-20px" />
                </span>
                <span>Advance Payment Entry</span>
              </a>
            </li>
          </ul>
          <ul v-if="isReceiptEntryRoutes"
            class="menu-list">
            <li>
              <a class="is-flex is-align-items-center has-text-light"
                @click="factorInvoices">
                <span class="icon pr-3">
                  <i class="mdi mdi-alpha-f mdi-20px" />
                </span>
                <span>Advance Payment Entry</span>
              </a>
            </li>
            <li>
              <a class="is-flex is-align-items-center has-text-light"
                @click="advanceReceiptEntry">
                <span class="icon pr-3">
                  <i class="mdi mdi-currency-usd mdi-20px" />
                </span>
                <span>Advance Receipt Entry</span>
              </a>
            </li>
            <li>
              <a class="is-flex is-align-items-center has-text-light"
                @click="bankUndepositedFunds">
                <span class="icon pr-3">
                  <i class="mdi mdi-bank-plus mdi-20px" />
                </span>
                <span>Bank Un-Deposited Funds</span>
              </a>
            </li>
          </ul>
        </div>
      </tippy>
    </div>
  </nav>
</template>

<script>
import HttpStatus from '@/components/http-status'
import EventBus from '@/components/EventBus'
import { mapGetters } from 'vuex'
import { EventHubTypes, AppHeaderButtonTypes } from '@/enums'
import QuoteDetailHeaderStartMenu from './QuoteDetailHeaderStartMenu.vue'
import QuoteRoutes from '@/views/quote/route-types'
import DebtorListRoutes from '@/views/debtorlist/route-types'
import PaymentEntryRoutes from '@/views/paymententry/route-types'
import ReceiptEntryRoutes from '@/views/receiptentry/route-types'
import QuoteTemplateService from '@/services/QuoteTemplateService'
import { QuotingMethodTypes } from '../../enums'

export default {
  name: 'AppDetailHeader',
  components: {
    QuoteDetailHeaderStartMenu
  },
  props: {
    showIcons: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    showNew: {
      type: Boolean,
      default: true
    },
    showPrint: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: true
    },
    showSave: {
      type: Boolean,
      default: true
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showOptions: {
      type: Boolean,
      default: false
    },
    showToggle: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: null
    },
    vehicle: {
      type: Object,
      default: null
    },
    sideSubMenu: {
      type: String,
      default: null
    },
    areTemplatesDisabled: {
      type: Boolean,
      default: false
    },
    addNewText: {
      type: String,
      default: 'New'
    },
    showLock: {
      type: Boolean,
      default: false
    },
    buttonsEnabled: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      serviceError: false,
      access: {},
      showSideMenu: true
    }
  },
  computed: {
    buttonCSS: function () {
      return 'is-inverted'
    },
    ...mapGetters(['licenceExpired']),
    ...mapGetters('quotes', {snapshotDiff: 'snapshotDiff'}),
    quoteRoutes() {
      return QuoteRoutes
    },
    isQuoteRoutes() {
      const objectNames = Object.keys(QuoteRoutes)
      let routeNames = []
      objectNames.forEach((name) => {
        routeNames.splice(routeNames.length, 1, name)
      })
      return routeNames.includes(this.$route.name)
    },
    isDebtorListRoutes() {
      const objectNames = Object.keys(DebtorListRoutes)
      let routeNames = []
      objectNames.forEach((name) => {
        routeNames.splice(routeNames.length, 1, name)
      })
      return routeNames.includes(this.$route.name)
    },
    isPaymentEntryRoutes() {
      return this.$route.name === PaymentEntryRoutes.PaymentEntryListView.name
    },
    isReceiptEntryRoutes() {
      return this.$route.name === ReceiptEntryRoutes.ReceiptEntryListView.name
    },
    quotingMethodTypes() {
      return QuotingMethodTypes
    },
    showOptionBtn() {
      return (this.isQuoteRoutes
              || this.isDebtorListRoutes
              || this.isPaymentEntryRoutes
              || this.isReceiptEntryRoutes)
            && !!this.entity
            && !this.licenceExpired
            && this.access.isEdit
            && !this.serviceError
    }
  },
  watch: {},
  created() {
    EventBus.$on('serviceError', (data) => {
      this.handleBusEvent(data)
    })
    this.$eventHub.$on(EventHubTypes.AutoHideSideMenu, (data) => {
      this.handleSideMenuEvent(data)
    })
  },
  mounted() {
    if (this.$route && this.$route.meta && this.$route.meta.access) {
      this.access = this.$route.meta.access
    }
  },
  methods: {
    async createNewTemplate() {
      let name = window.prompt('Template Name', '')
      if (name == '') {
        return
      }

      const templateItems = await QuoteTemplateService.createQuoteTemplateFromQuote(this.entity.quoteId, name)

      if (!templateItems) this.$notification.error('Error', 'No items to save as template')
      else this.$notification.success('Success', 'Template created successfully')

      this.$eventHub.$emit(EventHubTypes.reloadTemplates)
    },
    buttonClasses(additionalClasses) {
      if (additionalClasses === undefined) {
        additionalClasses = ''
      }

      if (this.showIcons) {
        return 'is-flex is-flex-direction-column is-align-items-center'
      }

      return `custom-button-class is-flex is-flex-direction-row is-align-items-center button is-outlined has-text-white ${additionalClasses}`
    },
    addNew() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.AddNew)
    },
    print() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Print)
    },
    trash() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Delete)
    },
    save() {
      this.$eventHub.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Save)
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Save)
    },
    cancel() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Cancel)
    },
    createNewSupp() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.NewSupp)
    },
    copyQuote() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.CopyQuote)
    },
    exportQuote() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.ExportQuote)
    },
    printStatement() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.PrintDebtorListStatement)
    },
    advancePaymentEntry() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.AdvancePaymentEntry)
    },
    factorInvoices() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.FactorInvoices)
    },
    advanceReceiptEntry() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.AdvanceReceiptEntry)
    },
    bankUndepositedFunds() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.BankUndepositedFunds)
    },
    toggleSide() {
      this.showSideMenu = !this.showSideMenu
      this.$eventHub.$emit(EventHubTypes.ToggleSideMenu, this.showSideMenu)
    },
    handleBusEvent(data) {
      this.serviceError = data === HttpStatus.NOT_FOUND
    },
    handleSideMenuEvent(data) {
      this.showSideMenu = !data
    },
    toggleReadOnly() {
      this.$emit(EventHubTypes.AppDetailHeaderButtonClicked, AppHeaderButtonTypes.Lock)
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-button-class {
  .mdi {
    font-size: 1em !important;
  }

  .is-size-8 {
    font-size: 14px !important;
  }
}
.menu-list a:hover {
  background-color: rgb(74, 80, 88) !important;
}
a.is-active .menu-list {
  background-color: rgb(51,145,239) !important;
}
.menu.more-menu {
  min-width: 12rem !important;
}
.is-divider {
  margin: 0.5rem 0;
}
</style>
