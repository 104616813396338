import Guid from '@/components/Guid'
// import { calcTotalExGst, calcGst, roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default class ReturnItemPartsModel {
  constructor(vendorId, crReturnNo = '',
  creditReturnID = Guid.newGuid(), creditorInvoiceId = Guid.empty(),
  invcAmount = 0, invoiceNo = '', invoiceRef = '',
  model= '', partNo = '', poItemId = Guid.empty(), poNumber = null,
  poRetQty = 0, qtyCredited = 0, quoteNo = '', recDate = '',
  rced = 0, returnNo = 0, returnPartsID = Guid.newGuid(),
  returned = 0, returning = 0, script = '', totalAmount = 0,
  totalReturned = 0)
  {
    this.crReturnNo = crReturnNo
    this.creditReturnID = creditReturnID
    this.creditorInvoiceId = creditorInvoiceId
    this.invcAmount = invcAmount
    this.invoiceNo = invoiceNo
    this.invoiceRef = invoiceRef
    this.model = model
    this.partNo = partNo
    this.poItemId = poItemId
    this.poNumber = poNumber
    this.poRetQty = poRetQty
    this.qtyCredited = qtyCredited
    this.quoteNo = quoteNo
    this.recDate = recDate
    this.rced = rced
    this.return = returnNo
    this.returnPartsID = returnPartsID
    this.returned = returned
    this.returning = returning
    this.script = script
    this.totalAmount = totalAmount
    this.totalReturned = totalReturned
    this.vendorId = vendorId
    this.isNewLine = true
  }
}
