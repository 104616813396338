<template>
  <fragment v-if="entity">

    <router-link v-if="false"
      :to="{ name: quoteRoutes.QuoteHeader.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
      active-class="is-active"
      class="navbar-item"
      exact>
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-view-dashboard-edit-outline mdi-24px" />
        </div>
        <div class="menu-heading is-size-7 has-text-centered">Header</div>
      </div>
    </router-link>

    <a v-if="false"
      id="quote"
      class="navbar-item">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-file-edit-outline mdi-24px" />
        </div>
        <div class="menu-heading is-size-7 has-text-centered">QUOTE</div>
      </div>
    </a>

    <!--
    <a class="navbar-item"
      :class="{'is-active router-link-exact-active': isSubMenu('QuoteSelections')}"
      @click="setSubMenu('QuoteSelections')"
      v-if="$route.name == 'QuoteSelections'">
      <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-car-wrench mdi-24px" />
        </div>
        <div class="menu-heading is-size-7 has-text-centered">SELECTIONS</div>
      </div>
    </a>
    <div v-if="$route.name == 'QuoteSelections'"
      class="is-divider-vertical px-1" />
    -->

    <div class="navbar-item navbar-quote-item"
      :class="{'is-active ': isSubMenu('QuoteSummary')}"
      @click="setSubMenu('QuoteSummary')">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-paperclip mdi-24px" />
        </div>
        <div class="menu-heading has-text-centered">Summary</div>
        <div class="menu-heading-underline" />
      </div>
    </div>

    <div class="navbar-item navbar-quote-item"
      :class="{'is-active': isSubMenu('QuoteImages')}"
      @click="setSubMenu('QuoteImages')">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-file-image mdi-24px" />
        </div>
        <div class="menu-heading has-text-centered">Images</div>
        <div class="menu-heading-underline" />
      </div>
    </div>

    <div class="navbar-item navbar-quote-item"
      :class="{'is-active ': isSubMenu('QuoteDocuments')}"
      @click="setSubMenu('QuoteDocuments')">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-paperclip mdi-24px" />
        </div>
        <div class="menu-heading has-text-centered">DOCS</div>
        <div class="menu-heading-underline" />
      </div>
    </div>

    <div class="navbar-item navbar-quote-item"
      :class="{'is-active': isSubMenu('QuoteCommunications')}"
      @click="setSubMenu('QuoteCommunications')">
      <div class="is-flex is-flex-direction-column is-align-items-center ">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-message-text mdi-24px" />
        </div>
        <span class="menu-heading">Comms</span>
        <div class="menu-heading-underline" />
      </div>
    </div>

    <div class="navbar-item navbar-quote-item"
      :class="{'is-active': isSubMenu('QuoteRemarks')}"
      @click="setSubMenu('QuoteRemarks')">
      <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-note-edit mdi-24px" />
        </div>
        <span class="menu-heading">NOTES</span>
        <div class="menu-heading-underline" />
      </div>
    </div>

    <div class="navbar-item navbar-quote-item"
      v-if="!!ShowNewQuoteDetail"
      :class="{'is-active': isSubMenu('QuoteStatus')}"
      @click="setSubMenu('QuoteStatus')">
      <div class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-note-edit mdi-24px" />
        </div>
        <span class="menu-heading">Status</span>
        <div class="menu-heading-underline" />
      </div>
    </div>

    <div v-if="vehicle"
      class="is-divider-vertical px-2" />
    <div v-if="vehicle && showIcons"
      class="is-flex is-flex-direction-column is-justify-content-center pl-1">
      <div class="is-flex is-size-7 is-align-items-center">
        <span class="icon has-text-primary"
          v-if="showIcons">
          <icon icon="mdi:card-text"
            class="mdi mdi-18px" />
        </span>
        <span>: {{ vehicle.rego }}</span>
      </div>
      <div class="is-flex is-align-items-center">
        <span class="icon has-text-primary"
          v-if="showIcons">
          <icon icon="mdi:car"
            class="mdi mdi-18px" />
        </span>
        <span>: {{ vehicle.model?.make }} {{ vehicle.model?.model }}</span>
      </div>
    </div>
    <div v-if="vehicle && !showIcons"
      class="is-flex is-flex-direction-column is-justify-content-center pl-1">
      <div class="is-flex is-align-items-center">
        <span class="menu-info"
          :title="vehicle.rego + '; ' + vehicle.model?.make + vehicle.model?.model ">
          {{ vehicle.rego }}; {{ vehicle.model?.make }} {{ vehicle.model?.model }}
        </span>
      </div>
    </div>
    <div v-if="vehicle"
      class="is-divider-vertical px-2" />
    <div v-if="vehicle && !showIcons"
      class="is-flex is-flex-direction-column is-justify-content-center pl-1"
      title="Job Total Inc. Supps if any">
      <div class="is-flex is-align-items-center ">
        <span class="has-text-primary has-text-weight-bold is-size-6">
          <!-- Job Total: -->

          {{ $company.info.countryCode ==='AU' ? getTotal(true) : getTotal(false) | formatCurrency($userInfo.locale) }}

          <span class="text-annotation">
            {{ $company.info.countryCode ==='AU' ? 'Inc Gst' : 'Ex Gst' }}
          </span>
        </span>
      </div>
    </div>
    <div v-if="false"
      class="navbar-item has-dropdown is-hoverable">
      <router-link :to="{ name: quoteRoutes.QuoteInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
        class="navbar-link is-arrowless">
        <div class="is-flex is-flex-direction-column is-align-items-center">
          <div class="icon"
            v-if="showIcons">
            <i class="mdi mdi-receipt-text-outline mdi-24px" />
          </div>
          <div class="is-size-7 has-text-centered">Invoice</div>
        </div>
      </router-link>
      <div class="navbar-dropdown">
        <router-link :to="{ name: quoteRoutes.QuoteInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
          active-class="is-active"
          class="navbar-item"
          :class="{ 'is-disabled': entity.isNew}"
          exact>
          <div class="is-flex">
            <span class="icon"
              v-if="showIcons">
              <i class="mdi mdi-receipt-text-outline mdi-24px" />
            </span>
            <span class="pl-2 is-size-7">Invoice</span>
          </div>
        </router-link>
        <router-link :to="{ name: quoteRoutes.QuoteExcessInvoice.name, params: { quoteId : $route.params.quoteId }, query: $route.query }"
          active-class="is-active"
          class="navbar-item"
          :class="{ 'is-disabled': entity.isNew}"
          exact>
          <div class="is-flex">
            <span class="icon"
              v-if="showIcons">
              <i class="mdi mdi-receipt-text-plus-outline mdi-24px" />
            </span>
            <span class="pl-2 is-size-7">Excess Invoice</span>
          </div>
        </router-link>
      </div>
    </div>
    <a v-if="false"
      id="quote"
      class="navbar-item">
      <div class="is-flex is-flex-direction-column is-align-items-center">
        <div class="icon"
          v-if="showIcons">
          <i class="mdi mdi-truck-fast mdi-24px" />
        </div>
        <div class="is-size-7 has-text-centered">Parts Control</div>
      </div>
    </a>
  </fragment>
</template>

<script>
import QuoteRoutes from '@/views/quote/route-types'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { Fragment } from 'vue-frag'
import { Icon } from '@iconify/vue2'
import { EventHubTypes } from '@/enums'

export default {
  name: 'QuoteDetailStartMenu',
  components: {
    Fragment,
    Icon
  },
  mixins: [NumberFiltersMixin],
  props: {
    showIcons: {
      type: Boolean,
      default: false
    },
    sideSubMenu: {
      type: String,
      default: null
    },
    entity: {
      type: Object,
      default: null
    },
    vehicle: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {}
  },
  computed: {
    quoteRoutes() {
      return QuoteRoutes
    },
    ShowNewQuoteDetail() {
      return this.$company?.setting?.newQuoteDetail || false
    },
    jobTotal() {
      return this.entity.totalExGst
    }
  },
  watch: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    isSubMenu(name) {
      // new quote builder - side panel, else check route
      if (this.ShowNewQuoteDetail) {
        return this.sideSubMenu == name
      } else {
        return this.$route.name == name
      }
    },
    setSubMenu(name) {
      //// side panel if new quote builder is true, else navigate to route
      if (!!this.ShowNewQuoteDetail) {
        this.$eventHub.$emit(EventHubTypes.subSidePanelChanged, name)
      } else {
        this.$router.push({ name: name, params: { quoteId: this.entity.quoteId }, query: this.$route.query })
      }
    },
    getTotal(incGst = true) {
      const key = incGst ? 'totalIncGST' : 'totalExGST'
      let total = incGst ? this.entity.totalIncGst : this.entity.totalExGst || 0
      this.entity.subQuoteNos?.forEach((subQuote) => {
        // only add quotes other than the current one
        // as current quote is already included in the total that changes value when parts are added live
        if (subQuote.value !== this.entity.quoteId) total += subQuote[key] || 0
      })
      return total
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.is-disabled {
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  // pointer-events: none;
  a {
    display: inline-block;
    // pointer-events: none;
    text-decoration: none;
  }
}

.menu-heading {
  text-transform: lowercase;
  font-size: 14px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  transition: all 0.25s ease-in-out;
}
.menu-heading-underline {
  width: 0%;
  height: 0px;
  background-color: #3291ef;
}

.menu-info {
  font-size: 14px;
  /// elipses
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.menu-heading::first-letter {
  text-transform: uppercase;
}

.text-annotation {
  font-size: 0.75rem;
}

/// underline on hover
.navbar-quote-item {
  padding-right: 0.25em;
  padding-left: 0.25em;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);

  &:hover,
  &.is-active {
    color: rgba(255, 255, 255, 1);
    .menu-heading-underline {
      height: 2px;
      width: 100%;
      transition: width 0.25s ease-in-out;
      border-radius: 10px 10px 0 0;
    }
  }
}
</style>
