import FqApi from '@/services/fq-api'

const baseUrl = '/quotetemplates'

export default {
  async getQuoteTemplates() {
    const url = `${baseUrl}/list`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteTemplateItems(templateId) {
    const url = `${baseUrl}/items/${templateId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async createQuoteTemplateFromQuote(quoteId, name) {
    const url = `${baseUrl}/create/${quoteId}`
    const result = await FqApi.get(url, { params: { name } })
    return result.data
  },
  async deleteQuoteTemplate(templateId) {
const url = `${baseUrl}/delete/${templateId}`
    const result = await FqApi.delete(url)
    return result.data

  }
}
