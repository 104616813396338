export default {
  EntitySaved: 'entitySaved',
  EntityReload: 'entityReload',
  EntityReloaded: 'entityReloaded',
  ToggleSideMenu: 'toggleSideMenu',
  AutoHideSideMenu: 'autoHideSideMenu',
  AppDetailHeaderButtonClicked: 'appDetailHeaderButtonClicked',
  SetActiveMenuCode: 'setActiveMenuCode',
  ImageCountChanged: 'imageCountChanged',
  DocumentCountChanged: 'documentCountChanged',
  ItemValueChanged: 'itemValueChanged',
  selectedButterflyCodesChanged: 'selectedButterflyCodesChanged',
  selectedButterflyGroupsChanged: 'selectedButterflyGroupsChanged',
  selectedButterflyPartsChanged: 'selectedButterflyPartsChanged',
  toggleButterflyModal: 'toggleButterflyModal',
  subSidePanelChanged: 'subSidePanelChanged',
  lastAddedItem: 'lastAddedItem',
  fixPendingValudaion: 'fixPendingValudaion',
  reloadTemplates: 'reloadTemplates'
}
