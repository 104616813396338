import { NotificationService } from '@/services'
import QuoteRoutes from '@/views/quote/route-types'
import { NotificationEntityType, NotificationStatusType, NotificationType } from '@/enums'

const MILLISECONDS_PER_MINUTES = 1000 * 60
const POLLING_INTERVAL = 0.5 * MILLISECONDS_PER_MINUTES

const state = {
  notifications: [],
  count: 0,
  intervalTimer: null
}

const getters = {
  notifications: (state) => state.notifications,
  count: (state) => state.notifications.length
}

const actions = {
  async getNotifications({commit}) {
    const notifications = await NotificationService.getNotifications(NotificationStatusType.Unread)
    notifications.forEach(notification => {
      if (notification.entityType === NotificationEntityType.Quote && notification.type === NotificationType.QuoteSmsIncoming) {
        // notification.route = `/quotes/${notification.entityId}/communications`
        notification.route = { name: QuoteRoutes.QuoteCommunications.name, params: { quoteId : notification.entityId } }
      }
    })
    commit('setNotifications', notifications)
  },
  startNotificationPoll({ commit, state, dispatch }) {
    if (!state.intervalTimer) {
      dispatch('getNotifications')
      commit('setIntervalTimer', () => { dispatch('getNotifications') })
    }
  },
  stopNotificationPoll({ commit, state }) {
    if (state.intervalTimer) {
      commit('clearIntervalTimer')
    }
  }
}

const mutations = {
  setIntervalTimer(state, callback) {
    if (state.intervalTimer) {
      clearInterval(state.intervalTimer)
    }
    state.intervalTimer = setInterval(() => {
      if (callback) {
        callback()
      }
    }, POLLING_INTERVAL)
  },
  clearIntervalTimer(state) {
    if (state.intervalTimer) {
      clearInterval(state.intervalTimer)
    }
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
