import FqApi from '@/services/fq-api'

const baseUrl = '/partscheck'

export default {
  async getLivePrices(quoteId) {
    const result = await FqApi.get(`${baseUrl}/live/getpartsprices/${quoteId}`)
    return result.data
  },
  async saveLivePrices(quoteId, packet) {
    const result = await FqApi.post(`${baseUrl}/live/savepartsprices/${quoteId}`, packet, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return result.data
  },
  async purchasePricedParts(quoteId, packet) {
    const result = await FqApi.post(`${baseUrl}/live/purchasepricedparts/${quoteId}`, packet, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return result.data
  }
}
