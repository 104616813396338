export class TableColumn {
  constructor(title, name, sortable, defaultOrder, align, isVisible = true, category = null, colspan=1, width=null) {
    this.title = title
    this.name = name
    this.sortable = sortable
    this.defaultOrder = defaultOrder
    this.align = align
    this.isVisible = isVisible
    this.category = category
    this.colspan = colspan
    this.width = width
  }
}
