import QuoteRoutes from './route-types'
import store from '@/store'

// Lazy-load components. Webpack will split each into individual file
const QuoteListView = r => require.ensure([], () => r(require('./QuoteListView.vue')), 'quote-list-chunk')
const QuoteView = r => require.ensure([], () => r(require('./QuoteView.vue')), 'quote-detail-chunk')
const QuoteDetail = r => require.ensure([], () => r(require('./QuoteDetail.vue')), 'quote-detail-chunk')
const QuoteVehicleCustomer = r => require.ensure([], () => r(require('./QuoteVehicleCustomer.vue')), 'quote-vehiclecustomer-chunk')
const QuoteRatesMarkups = r => require.ensure([], () => r(require('./QuoteRatesMarkups.vue')), 'quote-rates-chunk')
const QuoteRemarks = r => require.ensure([], () => r(require('./QuoteDetailComponentsOld/QuoteRemarksOld.vue')), 'quote-remarks-chunk')
const QuoteImages = r => require.ensure([], () => r(require('./QuoteDetailComponentsOld/QuoteImagesOld.vue')), 'quote-images-chunk')
const QuoteImagesBreakout = r => require.ensure([], () => r(require('./QuoteImagesBreakOut.vue')), 'quote-imagesbreakout-chunk')
const QuoteDocuments = r => require.ensure([], () => r(require('./QuoteDetailComponentsOld/QuoteDocumentsOld.vue')), 'quote-documents-chunk')
const QuoteLabour = r => require.ensure([], () => r(require('./QuoteLabour.vue')), 'quote-labour-chunk')
const QuoteOtherLabour = r => require.ensure([], () => r(require('./QuoteOtherLabour.vue')), 'quote-other-chunk')
const QuotePart = r => require.ensure([], () => r(require('./QuotePart.vue')), 'quote-part-chunk')
const QuoteOpg = r => require.ensure([], () => r(require('./QuoteOpg.vue')), 'quote-opg-chunk')
const QuoteMisc = r => require.ensure([], () => r(require('./QuoteMisc.vue')), 'quote-misc-chunk')
const QuoteSublet = r => require.ensure([], () => r(require('./QuoteSublet.vue')), 'quote-sublet-chunk')
const QuotePnetAudanet = r => require.ensure([], () => r(require('./QuotePnetAudanet.vue')), 'quote-pnetaudanet-chunk')
const QuoteOrm = r => require.ensure([], () => r(require('./QuoteOrm.vue')), 'quote-orm-chunk')
const QuoteArnie = r => require.ensure([], () => r(require('./QuoteArnie.vue')), 'quote-arnie-chunk')
const QuoteAnnotations = r => require.ensure([], () => r(require('./QuoteAnnotations.vue')), 'quote-annotations-chunk')
const QuoteInvoice = r => require.ensure([], () => r(require('./QuoteInvoice.vue')), 'quote-invoice-chunk')
const QuoteAssessmentInvoice = (r) => require.ensure([], () => r(require('./QuoteAssessmentInvoice.vue')), 'assessment-invoice-chunk')
// const QuoteExcessInvoice = r => require.ensure([], () => r(require('./QuoteExcessInvoice.vue')), 'quote-excessinvoice-chunk')
const QuoteExcessInvoice = () => import(/* webpackChunkName: "quote-excessinvoice-chunk" */ './QuoteExcessInvoice.vue')
const QuoteItemsOverview = () => import(/* webpackChunkName: "quote-item-overview-chunk" */ './QuoteItemsOverview.vue')
const QuoteSelections = () => import(/* webpackChunkName: "quote-item-overview-chunk" */ './QuoteSelections.vue')
const QuoteCommunications = () => import(/* webpackChunkName: "quote-communications-chunk" */ './QuoteDetailComponentsOld/QuoteCommunicationsOld.vue')
const QuoteItemsAuthorise = () => import(/* webpackChunkName: "quote-item-overview-chunk" */ './QuoteItemsAuthorise.vue')
const AuthoriseView = () => import(/* webpackChunkName: "quote-item-overview-chunk" */ './AuthoriseView.vue')
const QuoteHeader = () => import(/* webpackChunkName: "quote-header-chunk" */ './QuoteHeader.vue')
const QuoteSummary = () => import(/* webpackChunkName: "quote-summary-chunk" */ './QuoteDetailComponentsOld/QuoteSummaryOld.vue')
const QuoteBooking = () => import(/* webpackChunkName: "quote-booking-chunk" */ './QuoteBooking.vue')
const QuotePartsControl = () => import(/* webpackChunkName: "quote-excessinvoice-chunk" */ './partsControl/QuotePartsControlView.vue')
const QuotePriceParts = () => import(/* webpackChunkName: "quote-header-chunk" */ './components/QuotePriceParts.vue')
// Get sessionStorage
const tag = 'Quote'
const getStorage = function (query) {
  const sessionId = store.getters['userInfo/info'].sessionId
  return sessionStorage.getItem(`${sessionId}|${tag}|${query}`)
}

const moduleId = 'A077'
const invoiceModuleId = 'A035'
const excessInvoiceModuleId = 'A093'

export default [
  {
    path: QuoteRoutes.QuoteListView.path,
    name: QuoteRoutes.QuoteListView.name,
    component: QuoteListView,
    meta: {
      id: moduleId,
      route: 'listing',
      title: 'Quotes',
      fkey: 'Quote|filter',
      ekey: 'emailer',
      report: 'rptQuoteDollarHour',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: QuoteRoutes.QuoteHeader.path,
    name: '',
    component: QuoteView,
    // props: route => ({ isNew: route.meta.isNew, ...route.query }),
    // props: route => ({ ...route.params, ...route.query }),
    // props: route => Object.assign({}, route.params, route.query),
    props: (route) => ({
      isNew: route.meta.isNew,
      returnUrl: route.query.returnUrl || route.meta.returnUrl || getStorage('returnUrl'),
      invoiceType: Number(route.query.invoiceType) || Number(route.meta.invoiceType) || Number(getStorage('invoiceType'))
    }),
    meta: {
      id: moduleId,
      route: 'header',
      title: 'Quote',
      isNew: false,
      ekey: 'emailer'
    },
    children: [
      {
        path: '',
        name: QuoteRoutes.QuoteHeader.name,
        component: QuoteHeader,
        meta: {
          id: moduleId,
          route: 'header',
          title: 'Header'
        }
      },
      {
        path: QuoteRoutes.QuoteDetail.path,
        name: QuoteRoutes.QuoteDetail.name,
        component: QuoteDetail,
        meta: {
          id: moduleId,
          route: 'detail',
          title: 'Detail',
          isNew: false,
          ekey: 'emailer'
        }
      },
      {
        path: QuoteRoutes.QuoteSummary.path,
        name: QuoteRoutes.QuoteSummary.name,
        component: QuoteSummary,
        meta: {
          id: moduleId,
          route: 'summary',
          title: 'Summary'
        }
      },
      {
        path: QuoteRoutes.QuoteBooking.path,
        name: QuoteRoutes.QuoteBooking.name,
        component: QuoteBooking,
        meta: {
          id: moduleId,
          route: 'booking',
          title: 'Booking'
        }
      },
      {
        path: QuoteRoutes.QuotePnetAudanet.path,
        name: QuoteRoutes.QuotePnetAudanet.name,
        component: QuotePnetAudanet,
        meta: {
          id: moduleId,
          route: 'pnetaudanet',
          title: 'PNET/AudaNet'
        }
      },
      {
        path: QuoteRoutes.QuoteOrm.path,
        name: QuoteRoutes.QuoteOrm.name,
        component: QuoteOrm,
        meta: {
          id: moduleId,
          route: 'orm',
          title: 'ORM'
        }
      },
      {
        path: QuoteRoutes.QuoteArnie.path,
        name: QuoteRoutes.QuoteArnie.name,
        component: QuoteArnie,
        meta: {
          id: moduleId,
          route: 'arnie',
          title: 'Arnie'
        }
      },
      {
        path: QuoteRoutes.QuoteRatesMarkups.path,
        name: QuoteRoutes.QuoteRatesMarkups.name,
        component: QuoteRatesMarkups,
        meta: {
          id: moduleId,
          route: 'rates',
          title: 'Rates & Markups'
        }
      },
      {
        path: QuoteRoutes.QuoteVehicleCustomer.path,
        name: QuoteRoutes.QuoteVehicleCustomer.name,
        component: QuoteVehicleCustomer,
        meta: {
          id: moduleId,
          route: 'vehiclecustomer',
          title: 'Vehicle & Customer'
        }
      },
      {
        path: QuoteRoutes.QuoteImages.path,
        name: QuoteRoutes.QuoteImages.name,
        component: QuoteImages,
        meta: {
          id: moduleId,
          route: 'images',
          title: 'Images'
        }
      },
      {
        path: QuoteRoutes.QuoteImagesBreakout.path,
        name: QuoteRoutes.QuoteImagesBreakout.name,
        component: QuoteImagesBreakout,
        meta: {
          id: moduleId,
          route: 'imagesbreakout',
          title: 'Images Breakout'
        }
      },
      {
        path: QuoteRoutes.QuoteDocuments.path,
        name: QuoteRoutes.QuoteDocuments.name,
        component: QuoteDocuments,
        meta: {
          id: moduleId,
          route: 'documents',
          title: 'Documents'
        }
      },
      {
        path: QuoteRoutes.QuoteLabour.path,
        name: QuoteRoutes.QuoteLabour.name,
        component: QuoteLabour,
        meta: {
          id: moduleId,
          route: 'labour',
          title: 'Labour'
        }
      },
      {
        path: QuoteRoutes.QuoteOtherLabour.path,
        name: QuoteRoutes.QuoteOtherLabour.name,
        component: QuoteOtherLabour,
        meta: {
          id: moduleId,
          route: 'labour',
          title: 'Other Labour'
        }
      },
      {
        path: QuoteRoutes.QuotePart.path,
        name: QuoteRoutes.QuotePart.name,
        component: QuotePart,
        meta: {
          id: moduleId,
          route: 'part',
          title: 'Part'
        }
      },
      {
        path: QuoteRoutes.QuoteOpg.path,
        name: QuoteRoutes.QuoteOpg.name,
        component: QuoteOpg,
        meta: {
          id: moduleId,
          route: 'opg',
          title: 'Other Paint Group'
        }
      },
      {
        path: QuoteRoutes.QuoteMisc.path,
        name: QuoteRoutes.QuoteMisc.name,
        component: QuoteMisc,
        meta: {
          id: moduleId,
          route: 'misc',
          title: 'Miscellaneous'
        }
      },
      {
        path: QuoteRoutes.QuoteSublet.path,
        name: QuoteRoutes.QuoteSublet.name,
        component: QuoteSublet,
        meta: {
          id: moduleId,
          route: 'sublet',
          title: 'Sublet'
        }
      },
      {
        path: QuoteRoutes.QuoteRemarks.path,
        name: QuoteRoutes.QuoteRemarks.name,
        component: QuoteRemarks,
        meta: {
          id: moduleId,
          route: 'remarks',
          title: 'Remarks'
        }
      },
      {
        path: QuoteRoutes.QuoteAnnotations.path,
        name: QuoteRoutes.QuoteAnnotations.name,
        component: QuoteAnnotations,
        meta: {
          id: moduleId,
          route: 'annotations',
          title: 'Annotations'
        }
      },
      {
        path: QuoteRoutes.QuoteInvoice.path,
        name: QuoteRoutes.QuoteInvoice.name,
        component: QuoteInvoice,
        meta: {
          id: invoiceModuleId,
          route: 'invoice',
          title: 'Invoice'
        }
      },
      {
        path: QuoteRoutes.QuoteAssessmentInvoice.path,
        name: QuoteRoutes.QuoteAssessmentInvoice.name,
        component: QuoteAssessmentInvoice,
        meta: {
          id: invoiceModuleId,
          route: 'assessmentinvoice',
          title: 'Invoice'
        },
        props: true
      },
      {
        path: QuoteRoutes.QuoteExcessInvoice.path,
        name: QuoteRoutes.QuoteExcessInvoice.name,
        component: QuoteExcessInvoice,
        meta: {
          id: excessInvoiceModuleId,
          route: 'excessinvoice',
          title: 'Excess Invoice'
        }
      },
      {
        path: QuoteRoutes.QuoteItemsOverview.path,
        name: QuoteRoutes.QuoteItemsOverview.name,
        component: QuoteItemsOverview,
        meta: {
          id: moduleId,
          route: 'itemsoverview',
          title: 'Items Overview'
        }
      },
      {
        path: QuoteRoutes.QuoteSelections.path,
        name: QuoteRoutes.QuoteSelections.name,
        component: QuoteSelections,
        meta: {
          id: moduleId,
          route: 'itemsselections',
          title: 'Quote Selections'
        }
      },
      {
        path: QuoteRoutes.QuoteItemsAuthorise.path,
        name: QuoteRoutes.QuoteItemsAuthorise.name,
        component: QuoteItemsAuthorise,
        meta: {
          id: moduleId,
          route: 'itemsoauthoriseold',
          title: 'Items Authorise old'
        }
      },
      {
        path: QuoteRoutes.AuthoriseView.path,
        name: QuoteRoutes.AuthoriseView.name,
        component: AuthoriseView,
        meta: {
          id: moduleId,
          route: 'itemsoauthorise',
          title: 'Items Authorise'
        }
      },
      {
        path: QuoteRoutes.QuoteCommunications.path,
        name: QuoteRoutes.QuoteCommunications.name,
        component: QuoteCommunications,
        meta: {
          id: moduleId,
          route: 'communications',
          title: 'Communications'
        }
      },
      {
        path: QuoteRoutes.QuotePartsControl.path,
        name: QuoteRoutes.QuotePartsControl.name,
        component: QuotePartsControl,
        meta: {
          id: moduleId,
          route: 'partscontrol',
          title: 'Parts Control'
        }
      },
      {
        path: QuoteRoutes.QuotePriceParts.path,
        name: QuoteRoutes.QuotePriceParts.name,
        component: QuotePriceParts,
        meta: {
          id: moduleId,
          route: 'priceparts',
          title: 'Price Parts'
        }
      }
    ]
  }
]
