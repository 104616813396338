<template>
  <div class="">
    <div>
      <a name="btnAgent"
        class="button"
        :class="agentButtonClasses">
        <span class="icon">
          <!-- <i class="mdi mdi-toolbox mdi-24px" /> -->
          <icon icon="mdi:toolbox"
            class="mdi mdi-24px" />
        </span>
      </a>
    </div>
    <tippy ref="tippyAgent"
      to="btnAgent"
      arrow
      :interactive="true"
      theme="light"
      :max-width="500">
      <div class="container p-2 is-flex is-justify-content-space-between is-align-content-center">
        <span class="is-size-5">Agent</span>
      </div>
      <div class="is-divider mb-1 mt-0" />
      <div class="container">
        <div class="field">
          <label class="label">Device</label>
          <div class="select">
            <select v-model="deviceName"
              @change="changeDevice()">
              <option v-for="device in devices"
                :key="device">{{ device }}</option>
            </select>
          </div>
        </div>
        <div class="field">
          <label class="label">Resolution</label>
          <div class="select">
            <select v-model="resolutionName"
              @change="changeResolution()">
              <option v-for="resolution in resolutions"
                :key="resolution">{{ resolution }}</option>
            </select>
          </div>
        </div>
        <div class="field">
          <label class="label">Pixel Type</label>
          <div class="select">
            <select v-model="pixelTypeName"
              @change="changePixelType()">
              <option v-for="pixelType in pixelTypes"
                :key="pixelType">{{ pixelType }}</option>
            </select>
          </div>
        </div>
      </div>
    </tippy>
  </div>
</template>

<script>
import AgentService from '@/services/AgentService'
import StoreUtil from '@/store/utils'
import StoreMixin from './storeMixin'
import { Icon } from '@iconify/vue2'

export default {
  name: 'Agent',
  components: {
    Icon
  },
  mixins: [StoreMixin],
  props: {},
  data: () => {
    return {
      isAgentPresent: false,
      agentPollInterval: null,
      devices: [],
      resolutions: [],
      pixelTypes: [],
      deviceName: null,
      resolutionName: null,
      pixelTypeName: null
    }
  },
  computed: {
    agentButtonClasses() {
      if (this.isAgentPresent && !this.isScannerConfigured) {
        return 'is-warning'
      }

      if (this.isAgentPresent && this.isScannerConfigured) {
        return 'is-info'
      }

      return 'is-light'
    },
    isScannerConfigured() {
      return this.isAgentPresent && this.deviceName !== null && this.resolutionName !== null && this.pixelTypeName !== null
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.checkAgentExists()
    this.agentPollInterval = setInterval(async () => {
      this.checkAgentExists()
    }, 30 * 1000)

    this.deviceName = StoreUtil.getLocalStorage('scanner', 'deviceName') || null
    if (this.deviceName !== null) {
      this.updateDeviceInfomation()
    }
    this.resolutionName = StoreUtil.getLocalStorage('scanner', 'resolutionName') || null
    this.pixelTypeName = StoreUtil.getLocalStorage('scanner', 'pixelTypeName') || null

    this.$setIsScannerReady(this.isScannerConfigured)
  },
  beforeDestroy() {
    clearInterval(this.agentPollInterval)
  },
  methods: {
    async checkAgentExists() {
      try {
        await AgentService.hello()
        this.isAgentPresent = true
        if (this.devices.length == 0) {
          this.devices = await AgentService.Scanner.getDevices()
        }
      } catch (ex) {
        this.isAgentPresent = false
      }
      this.$setIsActive(this.isAgentPresent)
      this.$setIsScannerReady(this.isScannerConfigured)
    },
    changeDevice() {
      StoreUtil.setLocalStorage('scanner', 'deviceName', this.deviceName)
      console.log(this.deviceName)
      this.updateDeviceInfomation()
      this.$setIsScannerReady(this.isScannerConfigured)
    },
    changeResolution() {
      StoreUtil.setLocalStorage('scanner', 'resolutionName', this.resolutionName)
      this.$setIsScannerReady(this.isScannerConfigured)
    },
    changePixelType() {
      StoreUtil.setLocalStorage('scanner', 'pixelTypeName', this.pixelTypeName)
      this.$setIsScannerReady(this.isScannerConfigured)
    },
    async updateDeviceInfomation() {
      this.resolutions = await AgentService.Scanner.getResolutions(this.deviceName)
      this.pixelTypes = await AgentService.Scanner.getPixelTypes(this.deviceName)
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
</style>