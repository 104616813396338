<template>
  <div class="tile is-parent is-vertical pb-0 pl-0">
    <div class="tile is-child has-background-dark tile-border ">

      <DashboardPanel>
        <div class="panel-heading has-text-centered has-text-white custom-panel-heading">MESSAGE INBOX</div>

        <DashboardProgressBar :is-loading="isLoading"
          :initial-color="'#6C757D'"
          :loading-color="'#1c1c1b'" />

        <div class="tabs">
          <ul class="tab-list tab-override">
            <li @click="filterMessage(dashboardTabs.Today)"
              :class="[{'item-border': activeTab === dashboardTabs.Today}]"><a class="item"
                :class="[{'item-active': activeTab === dashboardTabs.Today}]">Today</a></li>
            <li @click="filterMessage(dashboardTabs.Yesterday)"
              :class="[{'item-border': activeTab === dashboardTabs.Yesterday}]">
              <a class="item"
                :class="[{'item-active': activeTab === dashboardTabs.Yesterday}]">Yesterday</a>
            </li>
            <li @click="filterMessage(dashboardTabs.ThisWeek)"
              :class="[{'item-border': activeTab === dashboardTabs.ThisWeek}]">
              <a class="item"
                :class="[{'item-active': activeTab === dashboardTabs.ThisWeek}]">This Week</a>
            </li>
          </ul>

        </div>

        <div class="message-container"
          :class="{'container-fullscreen':isUninvoicedJobsAndChartDisabled }">
          <div v-if="filteredMessages.length === 0"
            class="notification has-background-grey-dark has-text-centered">
            <span>No Messages</span>
          </div>
          <div v-for="(message,index) in filteredMessages"
            :key="index"
            class="is-flex is-justify-content-space-between ">
            <transition mode="out-in">
              <article class="message message-reply has-background-black has-text-grey  m-2"
                :class="[{'message-border' : !message.isRead}]">
                <div class="py-1 px-2">
                  <div class="is-flex is-justify-content-space-between pb-2">
                    <div class="is-flex">
                      <i class="icon mdi mdi-forum pr-2" />
                      <span class="help is-italic">From: 0{{ message.sender }}</span>
                    </div>

                    <div class="is-flex">
                      <div class="icon mdi mdi-briefcase-variant-outline " />

                      <router-link :to="{ path: `/quotes/${message.quoteId}/communications` }"
                        append>
                        {{ message.quoteNoRef }}
                      </router-link>
                    </div>

                  </div>

                  <p class="is-flex message-line "
                    :class="[{'has-text-weight-bold has-text-white message-line':!message.isRead}]">
                    {{ message.message }}
                  </p>

                  <div class="is-flex is-justify-content-space-between">
                    <span class="is-flex help is-italic">{{ $filters.formatDateTimeLocale(message.createdDate, $userInfo.locale) }}</span>
                    <span v-if="message.isRead"
                      class="is-flex help is-italic">Read by {{ message.readBy }}</span>
                  </div>
                </div>
              </article>
            </transition>
            <div class="is-flex is-justify-content-center is-align-items-center is-clickable"
              @click="setMessageStatus(message)">
              <span class="icon">
                <i v-if="!message.isRead"
                  class="mdi mdi-message has-text-info" />
                <i v-if="message.isRead"
                  class="mdi mdi-message-check-outline" />
              </span>
            </div>
          </div>
        </div>
      </DashboardPanel>
      <div />

    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { dashboardTabs } from '@/views/dashboard/helper'
import DashboardPanel from '@/views/dashboard/DashboardPanel.vue'
import DashboardService from '@/views/dashboard/DashboardService'
import DashboardProgressBar from '@/views/dashboard/DashboardProgressBar.vue'

export default {
  name: 'DashboardIncomingMessages',
  components: { DashboardProgressBar, DashboardPanel },
  mixins: [],
  props: {
    isUninvoicedJobsAndChartDisabled: Boolean
  },
  data() {
    return {
      filteredMessages: [],
      activeTab: 0,
      messages: [],
      isLoading: false,
      timeOutId: 0
    }
  },
  computed: {
    dashboardTabs() {
      return dashboardTabs
    }
  },
  created() {
    this.constantlyUpdateData()
  },
  beforeDestroy() {
    if (this.timeOutId) clearTimeout(this.timeOutId)
  },
  methods: {
    async getData() {
      this.isLoading = true
      this.messages = await DashboardService.getDashboardIncomingMessages()
      this.messages.sort(this.compareDates)
      this.filterMessage(this.activeTab)
      this.isLoading = false
    },

    constantlyUpdateData() {
      this.getData()
      // we want to update the data every 30 seconds
      this.timeOutId = setTimeout(this.constantlyUpdateData, 1000 * 30)
    },

    filterMessage(id) {
      this.activeTab = id
      if (id === dashboardTabs.Today) {
        // get messages for today
        this.getTodayMessage()
      } else if (id === dashboardTabs.Yesterday) {
        this.getYesterdayMessage()
      } else {
        this.filteredMessages = this.messages
      }
    },

    getTodayMessage() {
      const start = DateTime.local().startOf('day').toMillis()
      this.filteredMessages = this.messages.filter((message) => {
        return Date.parse(message.createdDate) > start
      })
    },

    getYesterdayMessage() {
      // get messages for yesterday
      const start = DateTime.local().minus({ days: 1 }).startOf('day').toMillis()
      const end = DateTime.local().startOf('day').toMillis()

      this.filteredMessages = this.messages.filter((message) => {
        return Date.parse(message.createdDate) > start && Date.parse(message.createdDate) < end
      })
    },

    compareDates(a, b) {
      return Date.parse(b.createdDate) - Date.parse(a.createdDate)
    },

    getDisplayMessage(str) {
      // we only want to display 20 characters,
      if (str.length > 20) return str.substring(0, 20) + '...'
      return str
    },

    async setMessageStatus(message) {
      this.isLoading = true
      await DashboardService.setMessageStatus(message.id)
      await this.getData()
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
article.message {
  width: 100%;
  &.message-reply {
    justify-content: end;
    > .message-body {
      text-align: right;
      border-width: 0 4px 0 0;
    }
  }
}

.message-body {
  width: 100%;
}

.message-line {
  white-space: nowrap;
  display: inline-block;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20vw;
}

.message-line:hover {
  overflow-wrap: anywhere;
  overflow: visible;
  white-space: break-spaces;
  height: auto;
}

.container {
  max-height: calc(100vh - 37em);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.container-override {
  max-height: calc(100vh - 17em);
  overflow-y: auto;
  scroll-behavior: smooth;
}

.message-border {
  border-right: 5px solid #3291ef;
}
.tile-border {
  border-radius: 20px !important;
}

.tab-list :hover {
  color: white;
}
.item {
  color: darkgray;
}

.item-active {
  color: white;
}
.tab-override {
  border-bottom: 0 !important;
}
.item-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.custom-panel-heading {
  border-radius: 20px 20px 0 0 !important;
  background-color: #495057 !important;
}

.message-container {
  height: 46vh;
  overflow-y: auto;
  scroll-behavior: smooth;
  &.container-fullscreen {
    height: 77vh;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}
</style>
