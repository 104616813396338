import UserInfoModel from './UserInfoModel'

export default class {
  constructor(info = null, permissions = [])
  {
    this.info = new UserInfoModel(info)
    this.permissions = permissions
  }

  hasAccess(code) {
    const permission = this.permissions.find(p => p.codeName === code)
    if (permission) {
      return permission.hasAccess
    }
    return false
  }

  hasAdd(code) {
    const permission = this.permissions.find(p => p.codeName === code)
    if (permission) {
      return permission.hasAdd
    }
    return false
  }

  hasEdit(code) {
    const permission = this.permissions.find(p => p.codeName === code)
    if (permission) {
      return permission.hasEdit
    }
    return false
  }

  hasDelete(code) {
    const permission = this.permissions.find(p => p.codeName === code)
    if (permission) {
      return permission.hasDelete
    }
    return false
  }

  hasPrint(code) {
    const permission = this.permissions.find(p => p.codeName === code)
    if (permission) {
      return permission.hasPrint
    }
    return false
  }
}