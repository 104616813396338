<template>
  <div class="calendar-row-holder thin-scrollbar animate__animated animate__fadeIn"
    :class="[
      activeTimePeriod,
      { 'is-full-width' : !isSidePanelOpen, 'loading': !!isLoading,
        'six': numberOfDays === 6, 'seven': numberOfDays === 7,
        'is-flex is-flex-direction-column is-align-items-stretch': isMonthly
      }
    ]
    ">

    <!-- weekly/day mode, show times on the left hand side of calendar -->
    <div v-if="!isMonthly"
      class="calendar-sidebar-times">
      <div class="is-flex is-justify-content-center time-label">
        <span>Time</span>
      </div>
      <div class="calendar-row"
        v-for="(time, index) in weeklySideTimeBar"
        :key="time.hour + '-' + time.minutes + '-' + index"
        :class="{ 'mounted': isComponentMounted}">
        <div class="calendar-time-cell">
          <div>
            <span v-if="time.showTime">{{ time.dateObj.toFormat('h:mma') }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- week day names in monthly mode -->
    <div v-if="isMonthly"
      class="calendar-row box-shadow no-border-bottom"
      style="z-index: 1;"
      :class="{ 'mounted': isComponentMounted }">
      <div v-for="(day, index) in weekDaysArr"
        class="calendar-cell unclickable"
        :class="{'summary': day.className === 'summary-cell'}"
        style="height: 40px"
        :key="index">
        <div class="cell-weekday">
          <span>
            {{ day.name }}
          </span>
        </div>
      </div>
    </div>

    <div v-for="(week, index) in calendarArr"
      :key="index"
      class="calendar-row"
      style="flex-grow: 1;"
      :class="{ 'mounted': isComponentMounted,
                'is-flex-direction-row': isMonthly,
                'is-flex-direction-column': !isMonthly }">

      <!-- if weekly mode add a header for week day name and date -->
      <div v-if="!isMonthly"
        class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center calendar-day-cell"
        :class="{ 'has-text-weight-bold': week.isToday, 'first-cell': index === 0 }">
        <span>{{ week.dateObj.toFormat('ccc') }}</span>
        <span>{{ week.dateObj.toFormat('d') }}</span>
      </div>

      <div v-for="(day, index1) in week.days"
        :draggable="!(isQuoteDragging && !day.isCurrentOrFuture)"
        @dragover="dragOver($event)"
        @dragleave="dragExit($event)"
        @drop="drop($event, day, isMonthly)"
        @click="handleCellClick([day])"
        :key="index-index1"
        :ref="day.date"
        class="calendar-cell"
        :class="{ 'is-today': day.isToday,
                  'disabled-cell': (!day.isThisMonth || !day.isNonWorkingDay || (isQuoteDragging && !day.isCurrentOrFuture)),
                  // selected cell is an array of selected cells, check if only one cell is selected and if it is the same as the current cell
                  'is-active':selectedCell?.length == 1 && selectedCellDateStr == day.dateString && isSidePanelOpen,
                  // 'unclickable': !day.bookings.length,
                  'monthMode': isMonthly
        }">
        <div v-if="isMonthly"
          class="cell-date">
          <div> {{ day.date }}</div>
        </div>

        <!-- only show the booked quote number and total cost when in quote mode -->
        <div class="cell-bookings has-text-weight-bold"
          v-if="showBookingsAndCost && isMonthly">
          <div class="is-flex is-flex-direction-column is-align-items-center ">
            <span class="booking-cell-text-booked">{{ day.booked }}</span>
            <span class="jobs-label is-uppercase">Jobs</span>
          </div>
          <div class="is-flex is-flex-direction-column is-align-items-center booking-cell-booked-cost">
            <span>{{ $filters.formatCurrency(calculateTotalSingleCellBookings(day.bookings).toFixed(0), $userInfo.locale, false, 0) }}</span>
          </div>
        </div>

        <!-- bookings in month mode -->
        <div v-else-if="isMonthly"
          class="is-flex is-justify-content-center is-align-content-center">
          <div v-if="day.bookings.length < 4"
            style="width: 100%;">
            <div v-for="(booking, indexbooking) in day.bookings"
              :key="indexbooking">
              <div class="quote-number-label">
                <span>#{{ booking.jobNumber }}</span>
              </div>
            </div>
          </div>
          <div v-else
            class="is-flex is-justify-content-center is-align-content-center">
            <span class="total-bookings-number">
              {{ day.bookings.length }}
            </span>
          </div>
        </div>

        <!-- bookings in weekly/day mode and there is atleast one booking -->
        <div v-else-if="day.bookings.length === 1">
          <calendar-weekly-bookings :booking="day.bookings[0]"
            :min-time="minTime"
            :max-time="maxTime"
            :index="index1" />
        </div>
      </div>

      <!-- <calendar-stacked-bookings v-if="!isMonthly"
        :bookings="week.days"
        :min-time="minTime"
        :max-time="maxTime" /> -->

      <!-- shows totals in the last cell if showBookingsAndCost mode  -->
      <div v-if="showBookingsAndCost && isMonthly"
        class="calendar-cell monthMode summary"
        :class="{ 'is-selected': isSidePanelOpen && selectedCell?.length > 1 && selectedCellDateStr === week.days[0].dateString + ' - ' + week.days[week.days.length - 1].dateString }"
        @click="handleCellClick(week.days)">
        <div class="cell-bookings">
          <div class="is-flex is-flex-direction-column is-align-items-center mt-5">
            <span class="booking-cell-text-booked-summary">{{ calculateTotals(week.days, 'booked') }}</span>
            <span class="total-jobs-text">Total Jobs</span>
          </div>
          <div class="is-flex is-flex-direction-column is-align-items-center booking-cell-booked-cost">
            <span class="is-size-5 has-text-weight-semibold">{{ $filters.formatCurrency(calculateTotalCosts(week.days, 'totalIncGst').toFixed(0), $userInfo.locale, false, 0) }}</span>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import { nextTick } from 'vue'
import CalendarWeeklyBookings from './CalendarWeeklyBookings'
// import CalendarStackedBookings from './CalendarStackedBookings.vue'
export default {
  name: 'CalendarComponent',
  components: {
    CalendarWeeklyBookings
    // , CalendarStackedBookings
  },
  props: {
    calendarArr: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isMonthly: {
      type: Boolean,
      required: true
    },
    showBookingsAndCost: {
      type: Boolean,
      required: false,
      default: true
    },
    weeklySideTimeBar: {
      type: Array,
      required: true
    },
    isQuoteDragging: {
      type: Boolean,
      required: false,
      default: false
    },
    activeTimePeriod: {
      type: String,
      required: true
    },
    isSidePanelOpen: {
      type: Boolean,
      required: true
    },
    numberOfDays: {
      type: Number,
      required: true
    },
    scrollToTimeString: {
      type: String,
      required: false
    },
    minTime: {
      type: Object,
      required: false
    },
    maxTime: {
      type: Object,
      required: false
    },
    hiddenWeekdays: {
      type: Array,
      required: false,
      default: () => []
    },
    selectedCell: {
      type: Array,
      required: false
    },
    selectedCellDateStr: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      ref: null,
      isComponentMounted: true,
      draggableList: [],
      weekDays: [
        { name: 'Sunday', className: '', dayIndex: 7 },
        { name: 'Monday', className: '', dayIndex: 1 },
        { name: 'Tuesday', className: '', dayIndex: 2 },
        { name: 'Wednesday', className: '', dayIndex: 3 },
        { name: 'Thursday', className: '', dayIndex: 4 },
        { name: 'Friday', className: '', dayIndex: 5 },
        { name: 'Saturday', className: '', dayIndex: 6 },
        { name: 'Totals', className: 'summary-cell' }
      ]
    }
  },
  computed: {
    weekDaysArr() {
      return this.weekDays.filter((day) => !this.hiddenWeekdays.includes(day.dayIndex) || !!day.className)
    }
  },
  watch: {
    activeTimePeriod: function (val) {
      nextTick(() => {
        this.scrollToTime()
      })
    }
  },
  mounted() {
    this.scrollToTime()
  },
  methods: {
    dragOver(e) {
      e.target.classList.add('dropzone')
      e.preventDefault()
      return false
    },
    dragExit(e) {
      e.target.classList.remove('dropzone')
    },
    drop(e, day, isMonthly) {
      e.target.classList.remove('dropzone')
      this.$emit('openModal')
    },
    scrollToTime() {
      if (!this.isMonthly) {
        this.$refs[this.scrollToTimeString][0].scrollIntoView({ behavior: 'smooth' })
      }
    },
    handleCellClick(day) {
      this.$emit('handleCellClick', day)
    },
    calculateTotalSingleCellBookings(bookings) {
      return bookings.reduce((a, b) => a + (b.totalIncGst || 0), 0)
    },
    calculateTotals(arr, key) {
      return arr.reduce((a, b) => a + (b[key] || 0), 0)
    },
    calculateTotalCosts(arr, key) {
      let total = 0
      arr.forEach((day) => {
        if (!!day.bookings.length) {
          total += day.bookings.reduce((a, b) => a + (b.totalIncGst || 0), 0)
        }
      })
      return total
    },
    isActive(day) {
      if (!this.selectedCell) {
        return false
      }
      if (this.selectedCell.dateObj === day.dateObj) {
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$dark-border: #ced4da;
$light-border: #e9ecef;
.calendar-row {
  display: flex;
  width: 100%;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  position: relative;
  &.mounted {
    opacity: 1;
  }

  &.box-shadow {
    box-shadow: 0px 1px 10px 0px #4f4e4e;
  }

  &.no-border-bottom {
    .calendar-cell {
      border-bottom: none !important;
    }
  }
}

.cell-weekday {
  display: flex;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  font-size: 1rem;
  text-transform: uppercase;
}

.calendar-row-holder {
  margin: 20px 20px 0px 20px;
  background: #f8f9fa;
  width: 74%;
  height: 97%;
  overflow-y: auto;
  transition: all 0.5s ease-in-out;

  // when side panel is closed
  &.is-full-width {
    width: 100% !important;
  }

  &.week {
    padding-right: 10px;
    display: inline-grid;
    grid-template-columns: 5% 19% 19% 19% 19% 19%;
    &.six {
      grid-template-columns: 5% 15.83% 15.83% 15.83% 15.83% 15.83% 15.83%;
    }
    &.seven {
      grid-template-columns: 5% 13.57% 13.57% 13.57% 13.57% 13.57% 13.57% 13.57%;
    }

    .calendar-cell {
      border-right: 1px solid $dark-border;
      border-bottom: 1px solid $light-border;
      &.is-today {
        box-shadow: inset 0px 2px 0px 0px #058cba;
      }
      &.disabled-cell {
        background-color: #f8f5f5 !important;
        cursor: default !important;
        pointer-events: none;
      }
    }
  }

  &.month {
    .calendar-row:nth-child(1) {
      border-top: 1px solid $dark-border;
    }

    .calendar-row {
      .calendar-cell:nth-child(1) {
        border-left: 1px solid $dark-border;
      }
    }
    .calendar-cell {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 100%;
      border-right: 1px solid $dark-border;
      border-bottom: 1px solid $dark-border;
      &.disabled-cell {
        background-color: #e9ecef;
      }
      &.unclickable {
        // days with no bookings
        cursor: default !important;
        pointer-events: none !important;
      }
    }
  }
  &.day {
    display: inline-grid;
    grid-template-columns: 5% 95%;

    .calendar-cell {
      border-right: 1px solid $dark-border;
      border-bottom: 1px solid $light-border;
      &.is-today {
        box-shadow: inset 0px 2px 0px 0px #3291ef;
      }
    }
  }

  &.loading {
    .calendar-row {
      opacity: 0.2;
    }

    opacity: 0.2;
    &::after {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100px;
      height: 100px;
      margin-top: -50px;
      margin-left: -50px;
      border-radius: 50%;
      border: 2px solid #ccc;
      border-top-color: #333;
      animation: spinner 0.6s linear infinite;

      @keyframes spinner {
        to {
          transform: rotate(360deg);
        }
      }
    }
  }
}
.calendar-time-cell {
  height: 30px;
  font-size: 0.8em;
  color: #000000;
  display: flex;
  justify-content: center;
}

.calendar-sidebar-times {
  border-right: 1px solid #ced4da;
  padding: 1px;
}
.calendar-day-cell {
  height: 50px;
  border-right: 1px solid $dark-border;
  border-bottom: 1px solid $light-border;
  border-top: 1px solid $dark-border;
  position: sticky;
  top: 0px;
  background: #ffffff;
  z-index: 99;
  text-transform: uppercase;
}
.calendar-cell {
  width: 100%;
  height: 30px;
  padding: 10px;
  background: rgb(255, 255, 255);
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;

  &.dropzone {
    background: #3291ef;
    box-shadow: 0 0px 30px rgba(255, 255, 255, 0.5);
    transition: all 0.1s ease-in;
  }
  &.is-today {
    .cell-date {
      div {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #3291ef;
        border-radius: 50%;
        min-width: 2em;
        min-height: 2em;
      }
    }
  }

  &.monthMode {
    height: 100% !important;
  }

  .cell-date {
    color: #495057;
    font-size: 0.875rem;
    display: flex;
  }

  // hover only for dates with bookings
  &:not(.unclickable):hover,
  &.is-active {
    background-color: #3291ef !important;
    .booking-cell-text-booked {
      color: #ffffff !important;
    }
    .cell-date {
      color: #ffffff !important;
    }
    .total-bookings-number {
      color: #ffffff !important;
    }
    .booking-cell-booked-cost {
      color: #ffffff !important;
    }
  }

  transition: all 0.25s ease-in-out;
  .cell-bookings {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    width: 100%;
  }

  &:not(.disabled-cell) {
    .booking-cell-text-booked {
      color: #3b97ff;
    }
  }
  .booking-cell-booked-cost {
    border-top: 1px solid #dee2e6;
    min-width: 60px;
    color: #495057;
    font-size: 1.25rem;
  }

  &.summary {
    background: #3b97ff !important;
    color: #ffffff !important;
    &.is-selected {
      box-shadow: -5px 0px 10px 1px #555555;
    }

    .cell-bookings {
      font-size: 1.5rem !important;

      .total-jobs-text {
        font-size: 0.75rem;
        color: #ffffff;
        text-transform: uppercase;
        font-weight: 100;
      }
    }

    .booking-cell-booked-cost {
      color: #ffffff;
      font-size: 1.125rem;
    }
  }
}

.booking-cell-text-booked {
  font-size: 1.5rem;
}

.booking-cell-text-booked-summary {
  font-size: 1.5rem;
}

.jobs-label {
  font-size: 0.75rem;
  color: #adb5bd;
  font-weight: 100;
}
.quote-number-label {
  border: 1px solid #3291ef;
  background-color: rgba(255, 255, 255, 1);
  margin-bottom: 1px;
  padding: 5px;
  color: #3291ef;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -0.6em;
  margin-right: -0.6em;
  border-radius: 2px;
}

.total-bookings-number {
  color: #3291ef;
  font-size: 26px;
  margin-top: 1em;
}

.time-label {
  height: 38px;
  opacity: 0;
  cursor: default;
}
</style>