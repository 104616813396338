//import _cloneDeep from 'lodash/cloneDeep'
import * as classHelper from '../../classHelper'

export default class {
  constructor(model) {
    this.companyId = ''
    this.isSupportUser = false
    this.displayName = ''
    this.companyName = ''
    this.withHoldingTax = 0
    this.locale = ''
    this.sessionId = ''
    this.userId = ''
    this.countryCode = ''
    this.isCustomerAdministrator = false
    this.isSystemAdministrator = true
    this.isSystemAccount = false
    this.firstName = ''
    this.lastName = ''
    this.scrollItemLabour = false
    this.scrollItemPart = false
    this.codemirrorTheme = ''
    this.theme = 'dark'

    if (model) {
      // Clone object from API
      // Object.assign(this, _cloneDeep(model))
      classHelper.shallowCopyValues(this, model)
    }
  }
}
