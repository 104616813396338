import { TableColumn } from '@/classes'
import NumberFiltersMixin from '@/components/mixins/filters/NumberFiltersMixin.js'
import { QuotingMethodTypes } from '@/enums'

const Columns = [
  new TableColumn('Quote No.', 'quoteNoRef', true, 'desc', 'left'),
  new TableColumn('Quote Date', 'quoteDate', true, 'desc', 'left'),
  new TableColumn('Rego', 'regoNo', true, 'desc', 'left'),
  new TableColumn('Make & Model', 'make,model', true, 'desc', 'left'),
  new TableColumn('Claim No.', 'claimNo', true, 'desc', 'left'),
  new TableColumn('Customer', 'customerName', true, 'desc', 'left'),
  new TableColumn('Insurer', 'insurerName', true, 'desc', 'left'),
  new TableColumn('Auth. Date', 'authorisedDate', true, 'desc', 'left'),
  new TableColumn('Status', 'quoteStatus', true, 'desc', 'left'),
  new TableColumn('Job Stage', 'jobStageText', false, 'desc', 'left'),
  new TableColumn('D/H', 'quotingMethod', true, 'desc', 'left'),
  new TableColumn("Inv'd", 'invoiced', true, 'desc', 'centered'), // eslint-disable-line
  new TableColumn("Ex Inv'd", 'excessInvoiced', true, 'desc', 'centered'), // eslint-disable-line
  new TableColumn('Active', 'active', false, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const LabourColumns = [
  new TableColumn('LN', 'lineNumber', true, 'desc', 'right'),
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Blend', 'blend', true, 'desc', 'centered'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Rate', 'rate', true, 'desc', 'right'),
  new TableColumn('Hours', 'hourValue', true, 'desc', 'right'),
  new TableColumn('Total', 'dollarValue', true, 'desc', 'right'),
  new TableColumn('Type', 'itemType', true, 'desc', 'left'),
  new TableColumn('Load', 'loadingPercent', true, 'desc', 'right'),
  new TableColumn('Present As', 'presentAs', false, 'desc', 'centered'),
  new TableColumn('Item Status', 'itemStatus', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const OtherLabourColumns = [
  new TableColumn('LN', 'lineNumber', true, 'desc', 'right'),
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Rate', 'rate', true, 'desc', 'right'),
  new TableColumn('Hours', 'hourValue', true, 'desc', 'right'),
  new TableColumn('Total', 'dollarValue', true, 'desc', 'right'),
  new TableColumn('Type', 'itemType', true, 'desc', 'left'),
  new TableColumn('Present As', 'presentAs', false, 'desc', 'centered'),
  new TableColumn('Item Status', 'itemStatus', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const PartColumns = [
  new TableColumn('LN', 'lineNumber', true, 'desc', 'right'),
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Cond.', 'mark', true, 'desc', 'centered'),
  new TableColumn('Qty', 'itemQuantity', true, 'desc', 'right'),
  new TableColumn('Unit Price', 'value', true, 'desc', 'right'),
  new TableColumn('Markup', 'markupValue', true, 'desc', 'right'),
  new TableColumn('Total', 'total', false, 'desc', 'right'),
  new TableColumn('Part No', 'partNo', true, 'desc', 'left'),
  new TableColumn('Ord', 'ordered', true, 'desc', 'centered'),
  new TableColumn('Recv', 'received', true, 'desc', 'centered'),
  new TableColumn('Rtnd', 'returned', true, 'desc', 'centered'),
  new TableColumn('Buy Price', 'buyPrice', true, 'desc', 'right'),
  new TableColumn('Item Status', 'itemStatus', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const OpgColumns = [
  new TableColumn('LN', 'lineNumber', true, 'desc', 'right'),
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Pt Grp', 'paintGroup', true, 'desc', 'centered'),
  new TableColumn('Hours', 'hourValue', true, 'desc', 'centered'),
  new TableColumn('Total', 'dollarValue', true, 'desc', 'right'),
  new TableColumn('Rate', 'rate', true, 'desc', 'right'),
  new TableColumn('Item Status', 'itemStatus', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const MiscColumns = [
  new TableColumn('LN', 'lineNumber', true, 'desc', 'right'),
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Ord', 'ordered', true, 'desc', 'centered'),
  new TableColumn('Recv', 'received', true, 'desc', 'centered'),
  new TableColumn('Total', 'value', true, 'desc', 'right'),
  new TableColumn('Present As', 'presentAs', false, 'desc', 'centered'),
  new TableColumn('Item Status', 'itemStatus', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const SubletColumns = [
  new TableColumn('LN', 'lineNumber', true, 'desc', 'right'),
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Type', 'subletTypeId', true, 'desc', 'left'),
  new TableColumn('Vendor', 'vendorId', true, 'desc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Ord', 'ordered', true, 'desc', 'centered'),
  new TableColumn('Recv', 'received', true, 'desc', 'centered'),
  new TableColumn('Total', 'value', true, 'desc', 'right'),
  new TableColumn('Buy Price', 'buyPrice', true, 'desc', 'right'),
  new TableColumn('Item Status', 'itemStatus', true, 'desc', 'centered'),
  new TableColumn('', 'subletAuthorityDocument', false, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const RemarkColumns = [
  new TableColumn('Created', 'remarkDateTime', false, 'desc', 'left'),
  new TableColumn('Remark', 'remarks', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'asc', 'centered')
]

const AvailablePartsColumns = [
  new TableColumn('Item No.', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left'),
  new TableColumn('Side', 'side', false, 'desc', 'left'),
  new TableColumn('Qty', 'quantity', false, 'desc', 'right'),
  new TableColumn('Price', 'unitPrice', false, 'desc', 'right'),
  new TableColumn('Part No.', 'partNo', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

const AvailableMiscColumns = [
  new TableColumn('Item No.', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left'),
  new TableColumn('Price', 'unitPrice', false, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

const AvailableSubletColumns = [
  new TableColumn('Item No.', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left'),
  new TableColumn('Price', 'unitPrice', false, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

const AvailableOpgColumns = [
  new TableColumn('Item No.', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

const AvailableLabourColumns = [
  new TableColumn('Item No.', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left'),
  new TableColumn('R&R', 'fgHour', false, 'desc', 'right'),
  new TableColumn('RWA', 'crushHour', false, 'desc', 'right'),
  new TableColumn('Repair', 'cdHour', false, 'desc', 'right'),
  new TableColumn('Paint', 'mechHour', false, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

const AvailableOtherLabourColumns = [
  new TableColumn('Item No.', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left'),
  new TableColumn('FG', 'fgHour', false, 'desc', 'right'),
  new TableColumn('Crush', 'crushHour', false, 'desc', 'right'),
  new TableColumn('C&D', 'cdHour', false, 'desc', 'right'),
  new TableColumn('Mech', 'mechHour', false, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'left')
]

const AvailableMainPartColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Side', 'side', true, 'desc', 'centered'),
  new TableColumn('Cond.', 'mark', true, 'desc', 'centered'),
  new TableColumn('Qty', 'itemQuantity', true, 'desc', 'right'),
  new TableColumn('Unit Price', 'value', true, 'desc', 'right'),
  new TableColumn('Markup', 'markupValue', true, 'desc', 'right'),
  new TableColumn('Part No', 'partNo', true, 'desc', 'left'),
  new TableColumn('Add', 'value', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const PartDeletedColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Side', 'side', true, 'desc', 'centered'),
  new TableColumn('Cond.', 'mark', true, 'desc', 'centered'),
  new TableColumn('Qty', 'itemQuantity', true, 'desc', 'right'),
  new TableColumn('Unit Price', 'value', true, 'desc', 'right'),
  new TableColumn('Markup', 'markupValue', true, 'desc', 'right'),
  new TableColumn('Part No', 'partNo', true, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const AvailableMainLabourColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Hours', 'hourValue', true, 'desc', 'right'),
  new TableColumn('Type', 'itemType', true, 'desc', 'left'),
  new TableColumn('Add', 'value', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const LabourDeletedColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Hours', 'hourValue', true, 'desc', 'right'),
  new TableColumn('Type', 'itemType', true, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const AvailableMainOtherLabourColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Hours', 'hourValue', true, 'desc', 'right'),
  new TableColumn('Type', 'itemType', true, 'desc', 'left'),
  new TableColumn('Add', 'value', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const OtherLabourDeletedColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Hours', 'hourValue', true, 'desc', 'right'),
  new TableColumn('Type', 'itemType', true, 'desc', 'left'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const AvailableMainOpgColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Hours', 'hourValue', true, 'desc', 'right'),
  new TableColumn('Add', 'value', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const AvailableMainMiscColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Rpt', 'reportOnly', true, 'desc', 'centered'),
  new TableColumn('Value', 'value', true, 'desc', 'right'),
  new TableColumn('Add', 'value', true, 'desc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const PartsEnquiryColumns = [
  new TableColumn('Item No.', 'itemNo', true, 'desc', 'left'),
  new TableColumn('Description', 'itemDesc', true, 'asc', 'left'),
  new TableColumn('Side', 'side', true, 'desc', 'centered'),
  new TableColumn('Cond.', 'mark', true, 'desc', 'centered'),
  new TableColumn('Qty', 'itemQuantity', true, 'desc', 'right'),
  new TableColumn('Unit Price', 'value', true, 'desc', 'right'),
  new TableColumn('Part No', 'partNo', true, 'desc', 'left'),
  // new TableColumn('', 'checkbox', false, 'desc', 'centered')
  new TableColumn('Sort No', 'sortNo', true, 'desc', 'left', false)
]

const ArnieColumns = [
  new TableColumn('Version', 'version', false, 'asc', 'centered'),
  new TableColumn('Sent', 'sentDateTime', false, 'asc', 'right'),
  new TableColumn('XML', 'xml', false, 'desc', 'centered'),
  new TableColumn('Success/Fail', 'isSuccess', false, 'desc', 'centered')
]

const QuoteSelectionsAvailableLabourColumns = [
  // new TableColumn('Item No.', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left', true, '', 1, 55),
  new TableColumn('R&R', 'fgHour', false, 'desc', 'centered', true, '', 1, 15),
  //new TableColumn('RWA', 'crushHour', false, 'desc', 'centered'),
  new TableColumn('Repair', 'cdHour', false, 'desc', 'centered', true, '', 1, 15),
  new TableColumn('Paint', 'mechHour', false, 'desc', 'centered', true, '', 1, 15)
]

const QuoteSelectionsAvailablePartsColumns = [
  // new TableColumn('Item', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left', true, '', 1, 85),
  new TableColumn('Add', 'quantity', false, 'desc', 'centered', true, '', 1, 15)
]

const QuoteSelectionsAvailableOpgColumns = [
  // new TableColumn('Item No.', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left', true, '', 1, 85),
  new TableColumn('Add', 'quantity', false, 'desc', 'centered', true, '', 1, 15)
]

const QuoteSelectionsAvailableMiscSubletColumns = [
  // new TableColumn('Item', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left', true, '', 1, 85),
  new TableColumn('Price', 'unitPrice', false, 'desc', 'centered', true, '', 1, 15)
]

const QuoteSelectionsAvailableOtherLabourColumns = [
  // new TableColumn('Item', 'itemNo', false, 'desc', 'left'),
  new TableColumn('Description', 'description', false, 'desc', 'left', true, '', 1, 85),
  //new TableColumn('FG', 'fgHour', false, 'desc', 'centered'),
  //new TableColumn('Crush', 'crushHour', false, 'desc', 'centered'),
  //new TableColumn('C&D', 'cdHour', false, 'desc', 'centered'),
  new TableColumn('Price', 'mechHour', false, 'desc', 'centered', true, '', 1, 15)
]

const nameLookupFunction = (packet) => {
  return packet.tab.name ?? packet.name
}

const QuoteSelectionsLabourColumns = [
  new TableColumn(nameLookupFunction, 'itemDesc', true, 'asc', 'left', true, '', 1, 40),
  new TableColumn('', 'reportOnly', true, 'asc', 'center', true, '', 1, 5),
  new TableColumn('Rate', 'rate', false, 'asc', 'right', true, '', 1, 15),

  new TableColumn(
    (packet) => {
      if (packet.innerValue.quotingMethod === QuotingMethodTypes.Hour) {
        let value = packet.data.filter(i => i.deleted !== true).reduce((total, row) => {
          return row.reportOnly ? total : total + row.hourValue
        }, 0)
        return NumberFiltersMixin.filters.formatNumber(value, packet.$userInfo.locale) + 'h'
      } else {
        let value = packet.data.reduce((total, row) => {
          return row.reportOnly ? total : total + row.dollarValue
        }, 0)

        return NumberFiltersMixin.filters.formatCurrency(value, packet.$userInfo.locale)
      }
    },
    'hourValue',
    true,
    'desc',
    'right',
    true,
    '',
    1,
    15
  ),
  new TableColumn(
    (packet) => {
      let value = packet.data.filter(i => i.deleted !== true).reduce((total, row) => {
        // only add to total if it's not a report only item
        return row.reportOnly ? total : total + row.dollarValue
      }, 0)

      return NumberFiltersMixin.filters.formatCurrency(value, packet.$userInfo.locale)
    },
    'dollarValue',
    true,
    'desc',
    'right',
    true,
    '',
    1,
    15
  ),
  new TableColumn('', 'action', false, 'desc', 'right', true, '', 1, 10)
]

const QuoteSelectionsOtherLabourColumns = [
  new TableColumn(nameLookupFunction, 'itemDesc', true, 'asc', 'left', true, '', 1, 40),
  new TableColumn('', 'reportOnly', true, 'asc', 'left', true, '', 1, 5),
  new TableColumn('Rate', 'rate', false, 'asc', 'right', true, '', 1, 15),
  new TableColumn(
    (packet) => {
      let value = packet.data.reduce((total, row) => {
        return row.reportOnly ? total : total + row.hourValue
      }, 0)

      return NumberFiltersMixin.filters.formatNumber(value, packet.$userInfo.locale) + 'h'
    },
    'hourValue',
    true,
    'desc',
    'right',
    true,
    '',
    1,
    15
  ),
  new TableColumn(
    (packet) => {
      let value = packet.data.reduce((total, row) => {
        // only add if item is not report only
        return row.reportOnly ? total : total + row.dollarValue
      }, 0)

      return NumberFiltersMixin.filters.formatCurrency(value, packet.$userInfo.locale)
    },
    'dollarValue',
    true,
    'desc',
    'right',
    true,
    '',
    1,
    15
  ),
  new TableColumn('', 'action', false, 'desc', 'centered', true, '', 1, 10)
]

const QuoteSelectionsPartColumns = [
  new TableColumn(nameLookupFunction, 'itemDesc', true, 'asc', 'left', true, '', 1, 38),
  new TableColumn('', 'partNo', true, 'desc', 'left', true, '', 1, 0),
  new TableColumn('', 'reportOnly', true, 'asc', 'left', true, '', 1, 5),
  new TableColumn('', 'mark', true, 'desc', 'centered', true, '', 1, 17),
  new TableColumn('', 'itemQuantity', true, 'desc', 'right', true, '', 1, 7),
  new TableColumn(
    (packet) => {
      let value = packet.data.reduce((total, row) => {
        let value = row.reportOnly ? 0 : row.itemQuantity * row.markupValue
        return total + value
      }, 0)

      return NumberFiltersMixin.filters.formatCurrency(value, packet.$userInfo.locale)
    },
    'value',
    false,
    'desc',
    'right',
    true,
    '',
    1,
    15
  ),
  new TableColumn('', 'action', false, 'desc', 'centered', true, '', 1, 18)
]

const QuoteSelectionsOpgColumns = [
  new TableColumn(nameLookupFunction, 'itemDesc', true, 'asc', 'left', true, '', 1, 52),
  new TableColumn('', 'reportOnly', true, 'asc', 'left', true, '', 1, 5),
  new TableColumn(
    (packet) => {
      // only add to total if it's not a report only item
      let value = row.reportOnly
        ? 0
        : packet.data.reduce((total, row) => {
            return total + row.hourValue
          }, 0)
      return NumberFiltersMixin.filters.formatNumber(value, packet.$userInfo.locale) + 'h'
    },
    'hourValue',
    true,
    'desc',
    'right',
    true,
    '',
    1,
    10
  ),
  new TableColumn(
    (packet) => {
      // only add to total if it's not a report only item
      let value = packet.data.reduce((total, row) => {
        return row.reportOnly ? total : total + row.dollarValue
      }, 0)

      return NumberFiltersMixin.filters.formatCurrency(value, packet.$userInfo.locale)
    },
    'dollarValue',
    true,
    'desc',
    'right',
    true,
    '',
    1,
    15
  ),
  new TableColumn('', 'action', false, 'desc', 'centered', true, '', 1, 18)
]

const QuoteSelectionsMiscColumns = [
  new TableColumn(nameLookupFunction, 'itemDesc', true, 'asc', 'left', true, '', 1, 62),
  new TableColumn('', 'reportOnly', true, 'asc', 'left', true, '', 1, 5),
  new TableColumn(
    (packet) => {
      // only add to total if it's not a report only item
      let value = packet.data.reduce((total, row) => {
        return row.reportOnly ? total : total + row.value
      }, 0)
      return NumberFiltersMixin.filters.formatCurrency(value, packet.$userInfo.locale)
    },
    'value',
    true,
    'desc',
    'right',
    true,
    '',
    1,
    15
  ),
  new TableColumn('', 'action', false, 'desc', 'right', true, '', 1, 18)
]

const QuoteSelectionsSubletColumns = [
  new TableColumn(nameLookupFunction, 'itemDesc', true, 'asc', 'left', true, '', 1, 61),
  //new TableColumn('Type', 'subletTypeId', true, 'desc', 'left', true, 1, '20%'),
  //new TableColumn('Vendor', 'vendorId', true, 'desc', 'left', true, 1, '20%'),
  new TableColumn('', 'reportOnly', true, 'asc', 'left', true, '', 1, 5),
  new TableColumn(
    (packet) => {
      let value = packet.data.reduce((total, row) => {
        // only add to total if it's not a report only item
        return row.reportOnly ? total : total + row.value
      }, 0)
      return NumberFiltersMixin.filters.formatCurrency(value, packet.$userInfo.locale)
    },
    'value',
    true,
    'desc',
    'right',
    true,
    '',
    1,
    16
  ),
  new TableColumn('', 'action', false, 'desc', 'centered', true, '', 1, 18)
]

const PricePartsColumns = [
  new TableColumn('Description', 'itemDesc', false, 'asc', 'left'),
  new TableColumn('OEM', 'oem', true, 'asc', 'centered', true, '', 1, 5),
  new TableColumn('Aftrm', 'aftrm', true, 'desc', 'centered', true, '', 1, 5),
  new TableColumn('Used', 'used', true, 'desc', 'centered', true, '', 1, 5),
  new TableColumn('Recon', 'recon', true, 'desc', 'centered', true, '', 1, 5),
  new TableColumn('Qty', 'itemQuantity', true, 'desc', 'right', true, '', 1, 5)
]

const PartsCheckPreferredSuppliersColumns = [
  new TableColumn('', 'action', false, 'desc', 'left'),
  new TableColumn('Preferred Suppliers', 'name', false, 'asc', 'left'),
  new TableColumn('Type', 'supplierType', false, 'asc', 'left')
]
const PartsCheckTier2SuppliersColumns = [
  new TableColumn('', 'action', false, 'desc', 'centered'),
  new TableColumn('Tier2 Suppliers', 'name', false, 'asc', 'left'),
  new TableColumn('Type', 'supplierType', false, 'asc', 'left')
]
const PartsCheckSpecialistSuppliersColumns = [
  new TableColumn('', 'action', false, 'desc', 'centered'),
  new TableColumn('Specialist Suppliers', 'name', false, 'asc', 'left'),
  new TableColumn('Type', 'supplierType', false, 'asc', 'left')
]

const PreferredSupplierColumns = [
  new TableColumn('Preferred Supplier', 'supplierName', true, 'asc', 'left'),
  new TableColumn('Type', 'type', true, 'asc', 'left'),
  new TableColumn('Location', 'location', true, 'asc', 'left'),
  new TableColumn('Rating', 'rating', true, 'asc', 'left'),
  new TableColumn('Status', 'status', true, 'asc', 'left'),
  new TableColumn('Remove Preferred', 'isPreferred', true, 'asc', 'center')
]

const SuggestedSupplierColumns = [
  new TableColumn('', 'action', false, 'desc', 'centered'),
  new TableColumn('Suggested Supplier', 'supplierName', true, 'asc', 'left'),
  new TableColumn('Location', 'location', true, 'asc', 'left'),
  new TableColumn('Rating', 'rating', true, 'asc', 'left'),
  new TableColumn('Add to Preferred', 'isPreferred', true, 'asc', 'center')
]

const PreferredSupplierDirectOrderColumns = [
  new TableColumn('', 'action', false, 'desc', 'centered'),
  new TableColumn('Preferred Supplier', 'supplierName', true, 'asc', 'left'),
  new TableColumn('Type', 'type', true, 'asc', 'left'),
  new TableColumn('Location', 'location', true, 'asc', 'left'),
  new TableColumn('Rating', 'rating', true, 'asc', 'left'),
  new TableColumn('Status', 'status', true, 'asc', 'left'),
  new TableColumn('Remove Preferred', 'isPreferred', true, 'asc', 'center')
]

const AuthorisedLabourColumns = [
  new TableColumn('Description', 'itemDesc', false, 'asc', 'left', true, 'Desc', 1, 32),
  new TableColumn('Rpt', 'reportOnly', false, 'desc', 'centered', true, 'Desc', 1, 3),
  new TableColumn('Rate', 'rateQuoted', false, 'desc', 'right', true, 'Quoted', 1, 11),
  new TableColumn('Hours', 'hourQuoted', false, 'desc', 'right', true, 'Quoted', 1, 8),
  new TableColumn('Amount', 'amountQuoted', false, 'desc', 'right', true, 'Quoted', 1, 12),
  new TableColumn('Rate', 'rateAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 11),
  new TableColumn('Hours', 'hourAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 8),
  new TableColumn('Amount', 'amountAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 12),
  new TableColumn('', 'action', false, 'desc', 'centered', true, 'Action', 1, 3)
]

const AuthorisedLabourDollarColumns = [
  new TableColumn('Description', 'itemDesc', false, 'asc', 'left', true, 'Desc', 1, 32),
  new TableColumn('Rpt', 'reportOnly', false, 'desc', 'centered', true, 'Desc', 1, 3),
  new TableColumn('Amount', 'amountQuoted', false, 'desc', 'right', true, 'Quoted', 1, 31),
  new TableColumn('Amount', 'amountAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 31),
  new TableColumn('', 'action', false, 'desc', 'centered', true, 'Action', 1, 3)
]

const AuthorisedPartColumns = [
  new TableColumn('Description', 'itemDesc', false, 'asc', 'left', true, 'Desc', 1, 25),
  new TableColumn('Part No.', 'partNo', false, 'asc', 'left', true, 'Desc', 1, 7),
  new TableColumn('Rpt', 'reportOnly', false, 'desc', 'centered', true, 'Desc', 1, 3),
  new TableColumn('Cond.', 'markQuoted', false, 'desc', 'centered', true, 'Quoted', 1, 6),
  new TableColumn('Qty', 'itemQuantityQuoted', false, 'desc', 'right', true, 'Quoted', 1, 4),
  new TableColumn('Unit Price', 'unitPriceQuoted', false, 'desc', 'right', true, 'Quoted', 1, 7),
  new TableColumn('Markup', 'markupValueQuoted', false, 'desc', 'right', true, 'Quoted', 1, 7),
  new TableColumn('Amount', 'amountQuoted', false, 'desc', 'right', true, 'Quoted', 1, 7),
  new TableColumn('Cond.', 'markAuthorised', false, 'desc', 'centered', true, 'Authorised', 1, 6),
  new TableColumn('Qty', 'itemQuantityAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 4),
  new TableColumn('Unit Price', 'unitPriceAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 7),
  new TableColumn('Markup', 'markupValueAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 7),
  new TableColumn('Amount', 'amountAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 7),
  new TableColumn('', 'action', false, 'desc', 'centered', true, 'Action', 1, 3)
]

const AuthorisedOpgColumns = [
  new TableColumn('Description', 'itemDesc', false, 'asc', 'left', true, 'Desc', 1, 32),
  new TableColumn('Rpt', 'reportOnly', false, 'desc', 'centered', true, 'Desc', 1, 3),
  new TableColumn('Pt Grp', 'paintGroupQuoted', false, 'desc', 'centered', true, 'Quoted', 1, 6),
  new TableColumn('Rate', 'rateQuoted', false, 'desc', 'right', true, 'Quoted', 1, 8),
  new TableColumn('Hours', 'hourQuoted', false, 'desc', 'right', true, 'Quoted', 1, 8),
  new TableColumn('Amount', 'amountQuoted', false, 'desc', 'right', true, 'Quoted', 1, 9),
  new TableColumn('Pt Grp', 'paintGroupAuthorised', false, 'desc', 'centered', true, 'Authorised', 1, 6),
  new TableColumn('Rate', 'rateAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 8),
  new TableColumn('Hours', 'hourAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 8),
  new TableColumn('Amount', 'amountAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 9),
  new TableColumn('', 'action', false, 'desc', 'centered', true, 'Action', 1, 3)
]

const AuthorisedOpgDollarColumns = [
  new TableColumn('Description', 'itemDesc', false, 'asc', 'left', true, 'Desc', 1, 32),
  new TableColumn('Rpt', 'reportOnly', false, 'desc', 'centered', true, 'Desc', 1, 3),
  new TableColumn('Pt Grp', 'paintGroupQuoted', false, 'desc', 'centered', true, 'Quoted', 1, 6),
  new TableColumn('Amount', 'amountQuoted', false, 'desc', 'right', true, 'Quoted', 1, 25),
  new TableColumn('Pt Grp', 'paintGroupAuthorised', false, 'desc', 'centered', true, 'Authorised', 1, 6),
  new TableColumn('Amount', 'amountAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 25),
  new TableColumn('', 'action', false, 'desc', 'centered', true, 'Action', 1, 3)
]

const AuthorisedMiscColumns = [
  new TableColumn('Description', 'itemDesc', false, 'asc', 'left', true, 'Desc', 1, 32),
  new TableColumn('Rpt', 'reportOnly', false, 'desc', 'centered', true, 'Desc', 1, 3),
  new TableColumn('Amount', 'amountQuoted', false, 'desc', 'right', true, 'Quoted', 1, 31),
  new TableColumn('Amount', 'amountAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 31),
  new TableColumn('', 'action', false, 'desc', 'centered', true, 'Action', 1, 3)
]

const AuthorisedSubletColumns = [
  new TableColumn('Description', 'itemDesc', false, 'asc', 'left', true, 'Desc', 1, 32),
  new TableColumn('Rpt', 'reportOnly', false, 'desc', 'centered', true, 'Desc', 1, 3),
  new TableColumn('Vendor', 'vendorIdQuoted', false, 'desc', 'left', true, 'Quoted', 1, 21),
  new TableColumn('Amount', 'amountQuoted', false, 'desc', 'right', true, 'Quoted', 1, 10),
  new TableColumn('Vendor', 'vendorIdAuthorised', false, 'desc', 'left', true, 'Authorised', 1, 21),
  new TableColumn('Amount', 'amountAuthorised', false, 'desc', 'right', true, 'Authorised', 1, 10),
  new TableColumn('', 'action', false, 'desc', 'centered', true, 'Action', 1, 3)
]

export {
  Columns,
  LabourColumns,
  OtherLabourColumns,
  PartColumns,
  OpgColumns,
  MiscColumns,
  SubletColumns,
  RemarkColumns,
  AvailablePartsColumns,
  AvailableMiscColumns,
  AvailableSubletColumns,
  AvailableOpgColumns,
  AvailableLabourColumns,
  AvailableOtherLabourColumns,
  AvailableMainPartColumns,
  PartDeletedColumns,
  AvailableMainLabourColumns,
  LabourDeletedColumns,
  AvailableMainOtherLabourColumns,
  OtherLabourDeletedColumns,
  AvailableMainOpgColumns,
  AvailableMainMiscColumns,
  PartsEnquiryColumns,
  ArnieColumns,
  QuoteSelectionsAvailableLabourColumns,
  QuoteSelectionsAvailablePartsColumns,
  QuoteSelectionsAvailableMiscSubletColumns,
  QuoteSelectionsAvailableOtherLabourColumns,
  QuoteSelectionsAvailableOpgColumns,
  QuoteSelectionsLabourColumns,
  QuoteSelectionsOtherLabourColumns,
  QuoteSelectionsPartColumns,
  QuoteSelectionsOpgColumns,
  QuoteSelectionsMiscColumns,
  QuoteSelectionsSubletColumns,
  PricePartsColumns,
  PartsCheckPreferredSuppliersColumns,
  PartsCheckTier2SuppliersColumns,
  PartsCheckSpecialistSuppliersColumns,
  PreferredSupplierColumns,
  SuggestedSupplierColumns,
  PreferredSupplierDirectOrderColumns,
  AuthorisedLabourColumns,
  AuthorisedLabourDollarColumns,
  AuthorisedPartColumns,
  AuthorisedOpgColumns,
  AuthorisedOpgDollarColumns,
  AuthorisedMiscColumns,
  AuthorisedSubletColumns
}
