import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('userInfo', { $userInfo: 'info' }),
    ...mapGetters('userInfo', { $user: 'user' })
  },
  methods: {
    // ...mapActions('userInfo', { getUserInfo: 'load' }),
    ...mapActions('userInfo', ['getUserPermissions', 'getUserInfo', '$setCodemirrorTheme', '$setScrollItemLabour', '$setScrollItemPart', '$setAppTheme'])
  }
}
