import CompanyRoutes from './route-types'

const CompanyOnboardingView = () => import(/* webpackChunkName: "company-onboarding-chunk" */ './onboarding/CompanyOnboardingView')
const CompanyMenuVersionAccess = () => import(/* webpackChunkName: "company-onboarding-chunk" */ './onboarding/CompanyMenuVersionAccess')
const CompanyEnableNewMenu = () => import(/* webpackChunkName: "company-onboarding-chunk" */ './onboarding/CompanyEnableNewMenu')
const CompanyUpdateUserAccess = () => import(/* webpackChunkName: "company-onboarding-chunk" */ './onboarding/CompanyUpdateUserAccess')
const NewFeaturesControl = () => import(/* webpackChunkName: "company-onboarding-chunk" */ './onboarding/NewFeaturesControl')

export default [
  {
    path: CompanyRoutes.CompanyMenuVersionAccess.path,
    name: '',
    component: CompanyOnboardingView,
    meta: {
      title: 'Company Onboarding',
      name: 'Company Onboarding'
    },
    children: [
      {
        path: '',
        name: CompanyRoutes.CompanyMenuVersionAccess.name,
        component: CompanyMenuVersionAccess,
        meta: {
          title: 'Menu Version Access',
          name: 'Menu Version Access'
        }
      },
      {
        path: CompanyRoutes.CompanyEnableNewMenu.path,
        name: CompanyRoutes.CompanyEnableNewMenu.name,
        component: CompanyEnableNewMenu,
        meta: {
          title: 'Menu Version Access',
          name: 'Menu Version Access'
        }
      },
      {
        path: CompanyRoutes.CompanyUpdateUserAccess.path,
        name: CompanyRoutes.CompanyUpdateUserAccess.name,
        component: CompanyUpdateUserAccess,
        meta: {
          title: 'Update User Access',
          name: 'Update User Access'
        }
      },
      {
        path: CompanyRoutes.NewFeaturesControl.path,
        name: CompanyRoutes.NewFeaturesControl.name,
        component: NewFeaturesControl,
        meta: {
          title: 'New Features Control',
          name: 'New Features Control'
        }
      }
    ]
  }
  // {
  //   path: CompanyRoutes.CompanyEnableNewMenu.path,
  //   name: CompanyRoutes.CompanyEnableNewMenu.name,
  //   component: CompanyEnableNewMenu,
  //   meta: {
  //     title: 'Company New Menu Access',
  //     name: 'Company New Menu Access'
  //   }
  // }
]
