import FqApi from '@/services/fq-api'

export default {
  async getItemTypes() {
    const url = '/companies/itemtypes'
    const result = await FqApi.get(url)
    return result.data
  },
  async enableAllV2() {
    const url = '/companies/enableallv2'
    const result = await FqApi.put(url)
    return result
  },
  async getEntity() {
    const url = '/companies/company'
    const result = await FqApi.get(url)
    return result.data
  },
  async getCompanyExternalSetting(settingName) {
    const url = `/companyexternalsettings/${settingName}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getClaimsTypes() {
    const url = '/companies/getClaimTypes'
    const result = await FqApi.get(url)
    return result.data
  },
  async grantNewMenuAccess(newMenu) {
    const url = '/companies/grantNewMenuAccess'
    const result = await FqApi.post(url, newMenu)
    return result.data
  },
  async alterCompanySettings(name, enable) {
    const url = ('/companies/alterCompanySetting' + '?name=' + name + '&enable=' + enable)
    const result = await FqApi.post(url)
    return result.data
  },
  async updateCompanySettings(companysettings) {
    const url = '/companysettings/UpdateCompanySettings'
    const result = await FqApi.post(url, companysettings)
    return result.data
  },
  async getCompanyPermissionRecords() {
    try {
      return await FqApi.get('/accesssetup/company')
    } catch (e) {
      throw e
    }
  },
  async updateCompanyPermissionRecord(permissionRecordId, version) {
    try {
      // this.$notification.success('Success', `Access updated`)
      return FqApi.put(`/accesssetup/company?permissionRecordId=${permissionRecordId}&version=${version}`)
    } catch (e) {
      throw e
    }
  },
  async getCompanyUsers() {
    try {
      const url = ('/companies/getCompanyUsers')
      const result = await FqApi.get(url)
      return result.data
    } catch (e) {
      return []
    }
  },
  async getCompanyUsersWithXeroAccess() {
    try {
      const url = ('/companies/getCompanyUsersWithXeroAccess')
      const result = await FqApi.get(url)
      return result.data
    } catch (e) {
      return []
    }
  },
  async updateUserAccess(userIds) {
    const url = ('/companies/updateAccessToXeroExport')
    const result = await FqApi.post(url, userIds)
    return result.data
  }
}