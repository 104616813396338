import { ComponentRoute } from '@/classes'

const CompanyRoutes = {
  CompanyOnboardingView: new ComponentRoute('/company/onboarding', 'CompanyOnboardingView'),
  CompanyMenuVersionAccess: new ComponentRoute('/company/onboarding/', 'CompanyMenuVersionAccess'),
  CompanyEnableNewMenu: new ComponentRoute('/company/onboarding/enablenewmenu', 'CompanyEnableNewMenu'),
  CompanyUpdateUserAccess: new ComponentRoute('/company/onboarding/updateuseraccess', 'CompanyUpdateUserAccess'),
  NewFeaturesControl: new ComponentRoute('/company/onboarding/newfeatures', 'NewFeaturesControl')
}

export default CompanyRoutes