import FqApi from '@/services/fq-api'

const baseUrl = '/notifications'

export default {
  async getNotifications(status) {
    const result = await FqApi.get(`${baseUrl}/list/${status}`)
    return result.data
  },
  async setNotificationStatus(id, status) {
    await FqApi.put(`${baseUrl}/setstatus/${id}/${status}`)
  }
}