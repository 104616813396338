import FqApi from '@/services/fq-api'

export default {
  async exportParts(quoteId, imageIds) {
    const url = `partscheck/submitparts/${quoteId}`
    const result = await FqApi.put(url, imageIds)
    return result.data
  },
  async getPartsPrices(quoteId) {
    const url = `partscheck/getpartsprices/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getAllSupplierAndVehicleMakeList() {
    const url = 'partscheck/getallsupplierandvehiclemakelist'
    const result = await FqApi.get(url)
    return result.data
  },
  async submitPriceParts(quoteId, pricePartsRequestModel) {
    console.log('test')
    console.log(pricePartsRequestModel)
    const url = `partscheck/submitpriceparts/${quoteId}`
    const result = await FqApi.put(url, pricePartsRequestModel)
    return result.data
  },
  async goToPartsCheck(pQuoteNo, searchType) {
    const url = `/partscheck/gotopartscheck/${pQuoteNo}/${searchType}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getPartsCheckDefaultSettings(){
    const url = '/partscheck/getpartscheckdefaultsettings'
    const result = await FqApi.get(url)
    return result.data
  },
  async savePartsCheckDefaultSettings(defaultSettings){
    const url = '/partscheck/savepartscheckdefaultsettings'
    const result = await FqApi.post(url, defaultSettings)
    return result.data
  },
  async removeFromPreferredSuppliersList(id, vehicleMakeId)
  {
    const url = `/partscheck/removefrompreferredsupplierslist/${id}/${vehicleMakeId}`
    const result = await FqApi.post(url)
    return result.data
  },
  async getSuppliers(stateId, vehicleMakeId) {
    const url = `partscheck/getsuppliers/${stateId}/${vehicleMakeId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async addPreferredSuppliersList(id, vehicleMakeId)
  {
    const url = `/partscheck/addtopreferredsupplierslist/${id}/${vehicleMakeId}`
    const result = await FqApi.post(url)
    return result.data
  }
}
